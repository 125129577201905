// 在 Vue 组件中引入 IndexedDB API
import Vue from 'vue';

const dbKey = {
    taker: 'taker',
    takerActiveId: 'talker_active_id',
    takerLog: 'taker_log',
    takerLogSumUp: 'taker_log_sum_up'
}

Vue.prototype.$dbKey = dbKey

Vue.prototype.$db = {
    db: null,
    dbName: 'my_db',
    dbStores: ['talker'],
    async openDB() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, 1);

            request.onupgradeneeded = event => {
                const db = event.target.result;
                this.dbStores.forEach(storeName => {
                    db.createObjectStore(storeName);
                })
            };

            request.onsuccess = event => {
                this.db = event.target.result;
                resolve();
            };

            request.onerror = event => {
                reject(event.target.error);
            };
        });
    },

    async saveData(storeName, key, data) {
        if (!this.db) {
            await this.openDB();
        }

        const transaction = this.db.transaction([storeName], 'readwrite');
        const objectStore = transaction.objectStore(storeName);
        objectStore.put(data, key);
    },

    async getData(storeName, key) {
        if (!this.db) {
            await this.openDB();
        }
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([storeName], 'readonly');
            const objectStore = transaction.objectStore(storeName);
            const request = objectStore.get(key);

            request.onsuccess = event => {
                resolve(event.target.result);
            };

            request.onerror = event => {
                reject(event.target.error);
            };
        });
    },

    async saveTalker(key, data) {
        await this.saveData("talker", key, data)
    },

    async getTalker(key) {
        return this.getData("talker", key)
    }
}
