<template>
    <div class="container animated fadeIn">
        <div class="footer-bg">
            <div class="footer-box">
                <div class="rule-line copyright">© <span class="copyright-date">2022-2024</span> xuchenoak</div>
                <div class="rule-line syst-light">
                    <a target="_blank" href="http://beian.miit.gov.cn">滇ICP备2022004898号-1</a>
                </div>
                <div class="rule-line syst-light">
                    <a target="_blank" href="http://www.beian.gov.cn/portal/recordQuery">闽公网安备 35030402009304号</a>
                </div>
                <div class="rule-line description syst-light">
                    <span>部分素材来源网络，若有侵权请联系删除，万分感谢！</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer",
        data() {
            return {

            }
        }
    }
</script>

<style lang="less" scoped>
    .container {
        width: 100%;
        background: rgba(255, 255, 255, 0.2);
        //background: rgba(0, 0, 0, 0.5);
        .footer-bg {
            width: 100%;
            height: 300px;
            max-width: 1200px;
            margin: 0 auto;
            padding: 70px 25px;
            display: flex;
            flex-wrap: wrap;
            .footer-box {
                width: 100%;
                height: 100%;

                .rule-line {
                    font-size: 14px;
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    >a {
                        color: #666;
                        display: inline-block;
                        position: relative;
                        font-weight: 400;
                    }
                    >a::before {
                        content: '';
                        width: 0;
                        height: 1px;
                        background-color: #666;
                        position: absolute;
                        bottom: 5px;
                        transition: all .3s;
                    }
                    >a:hover::before {
                        width: 100%;
                    }
                    >a:hover {
                        color: #444;
                    }
                }
                .copyright {
                    font-size: 17px;
                    font-weight: 600;
                    color: #555;
                    margin-bottom: 10px;
                    font-family: CaviarDreams;
                    .copyright-date {
                        font-size: 13px;
                    }
                }
                .description {
                    position: relative;
                    margin-top: 10px;
                    font-size: 13px;
                    color: #666;
                    padding-left: 8px;
                }
                .description::before {
                    content: '';
                    position: absolute;
                    top: 9px;
                    left: 2px;
                    width: 1px;
                    height: 14px;
                    background: #888;
                }
            }
        }
    }

</style>
