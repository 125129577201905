import request from "@/util/request";


/**
 * 获取页面数据
 * @returns {*}
 */
export function listPageData(classify) {
    return request({
        url: '/article/list_page_data',
        method: 'get',
        params: { classify }
    })
}

/**
 * 获取数据列表
 * @returns {*}
 */
export function listData(params) {
    return request({
        url: '/article/list_data',
        method: 'get',
        params: params
    })
}

/**
 * 根据类型获取数据列表
 * @returns {*}
 */
export function listDataByClassify(params) {
    return request({
        url: '/article/list_data_by_classify',
        method: 'get',
        params: params
    })
}

/**
 * 获取数据
 * @returns {*}
 */
export function getData(id) {
    return request({
        url: '/article/get_data',
        method: 'get',
        params: { id }
    })
}

/**
 * 获取页面数据
 * @returns {*}
 */
export function listSearch(params) {
    return request({
        url: '/article/list_search',
        method: 'get',
        params: params
    })
}