<template>
    <div class="music-box">
        <ul>
            <li :key="item.id" v-for="item in dataList">
                <div class="music-info-box">
                    <div class="music-info-bg">
                        <div class="music-info">
                            <div class="info-header">
                                <div class="header-picture-bg">
                                    <img @click="$toArticle(item)" class="header-picture diy-cursor" :src="$imagePath(item.themePictureFileId)" alt="">
                                </div>
                                <div class="header-title">
                                    <h2 @click="$toArticle(item)" class="diy-cursor">{{item.name}}</h2>
                                    <div style="color: #888">{{ item.author }}</div>
                                    <div style="margin-top: 10px"><tag-box-search :tags="item.tags"></tag-box-search></div>
                                </div>
                            </div>
                            <div class="info-intro">{{item.intro}}</div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import TagBoxSearch from "@/view/components/TagBoxSearch";
export default {
    name: "MusicListPh",
    components: {TagBoxSearch},
    props: {
        dataList: {
            type: Array,
            default() {return []}
        }
    }
}
</script>

<style scoped lang="less">
.music-box {
    width: 100%;
    >ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        >li {
            margin-bottom: 45px;
            list-style: none;
            min-height: 250px;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            .music-info-box {
                width: 100%;
                .music-info-bg {
                    width: 100%;
                    background-color: #fff;
                    box-shadow: rgb(0 0 0 / 5%) 2px 2px 20px;
                    transition: all .3s;
                    border-radius: 4px;
                    .music-info {
                        box-sizing: border-box;
                        .info-header {
                            padding: 15px;
                            overflow: hidden;
                            .header-picture-bg {
                                float: left;
                                height: 100px;
                                width: 100px;
                                border-radius: 4px;
                                overflow: hidden;
                                .header-picture {
                                    height: 100px;
                                    width: 100px;
                                    border-radius: 4px;
                                }
                            }
                            .header-title {
                                float: left;
                                width: calc(100% - 115px);
                                margin-left: 15px;
                                font-family: 阿里普惠体;
                                height: 100px;
                                >h2 {
                                    display: inline-block;
                                    margin-top: 5px;
                                    margin-bottom: 0;
                                    font-weight: 500;
                                    width: calc(100% - 15px);
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    word-break: break-all;
                                    white-space: nowrap;
                                }
                            }
                        }
                        .info-intro {
                            border-top: 1px dashed #eee;
                            padding: 15px;
                            text-align: justify;
                            font-size: 15px;
                            line-height: 27px;
                        }
                    }
                }
            }


        }
        .music-li-hover:hover {
            .music-picture-bg {
                box-shadow: rgb(0 0 0 / 15%) 2px 2px 30px;
                //opacity: 0.8;
                .music-picture {
                    transform: scale(1.05);
                }
            }
            .music-info-bg {
                box-shadow: rgb(0 0 0 / 10%) 2px 2px 30px;
            }
        }
        >li:last-child {
            margin-bottom: 0;
        }
    }

}
</style>
