<template>
    <span class="tag-box">
        <span v-for="(tag, index) in tags"
              :key="index + tag"
              class="diy-cursor tag-box-tag"
              :class="selectTags.includes(tag) ? 'tag-box-tag-select' : ''"
              :style="'--fontSize:' + fontSize"
              @click="handleTag(tag)">#{{tag}}</span>
    </span>
</template>

<script>
export default {
    name: "index",
    props: {
        tags: {
            type: Array,
            default() {
                return []
            }
        },
        selectTags: {
            type: Array,
            default() {
                return []
            }
        },
        fontSize: {
            type: String,
            default() {
                return '14px'
            }
        }
    },
    methods: {
        handleTag(tag) {
            this.$emit('handleTag', tag)
        }
    }
}
</script>

<style scoped lang="less">
.tag-box {
    .tag-box-tag {
        padding-right: 4px;
        cursor: pointer;
        font-size: var(--fontSize);
        color: #999;
        transition: all .2s;
        font-weight: 200;
        font-family: 阿里普惠体;
    }
    .tag-box-tag:hover {
        color: #444;
    }
    .tag-box-tag-select {
        color: #f40;
    }
}
</style>
