<template>
    <page-box :title="classify.text" :line-color="classify.color"  :more="{enabled: false, link: true, text: '更多……'}">
        <movie-list v-if="!phoneView" :data-list="dataList"/>
        <movie-list-ph v-else :data-list="dataList"/>
    </page-box>
</template>

<script>
import {listPageData} from "@/api/article";
import MovieList from "./components/MovieList";
import MovieListPh from "./components/MovieListPh";

export default {
    name: "index",
    components: {MovieList, MovieListPh},
    data() {
        return {
            phoneView: false,
            dataList: [],
            classify: this.$constant.classifyConst['MOVIE']
        }
    },
    created() {
        this.listData()
    },
    mounted() {
        this.phoneView = this.$store.state.phoneView
    },
    watch: {
        '$store.state.phoneView'() {
            this.phoneView = this.$store.state.phoneView
        },
    },
    methods: {
        listData() {
            listPageData("MOVIE").then(res => {
                this.dataList = res.rows
            })
        }
    }
}
</script>

<style scoped lang="less">

</style>
