<template>
    <div class="content-container animated fadeInDown">
        <div class="content-box" :class="phoneView ? 'content-box-phone' : ''">
            <p class="content-line-feed">Hi！我是许晨</p>
            <p>欢迎访问我的小破站</p>
            <p class="content-line-feed">小破站叫“<span style="font-family: CaviarDreams; font-size: 20px; font-weight: 600;">xuchenoak</span>”</p>
            <p>意为橡树之下的自己</p>
            <p>毕竟橡树之下好乘凉</p>

            <p class="content-line-feed">我想做一个学习者</p>
            <p>学习知识，学习技术，学习优秀的人</p>

            <p class="content-line-feed">我想做一个记录者</p>
            <p>记录学习，记录生活，记录遇见的你</p>

            <p class="content-line-feed">我想做一个分享者</p>
            <p>分享想法，分享爱好，分享生活与诗</p>

            <p class="content-line-feed">人生海海，欣喜相逢</p>
            <p>Hello World</p>
        </div>

    </div>
</template>

<script>
export default {
    name: "index",
    inject: ['setLoading'],
    data() {
        return {
            itemList: [],
            classifyConst: this.$constant.classifyConst,
            phoneView: false
        }
    },
    watch: {
        '$store.state.phoneView'() {
            this.phoneView = this.$store.state.phoneView
        },
    },
    mounted() {
        this.phoneView = this.$store.state.phoneView
    },
    computed: {
        isShow() {
            return this.itemList && this.itemList.length > 0
        }
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.delay6s {
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
    -o-animation-delay: 2s;
    animation-delay: 2s;
}
.content-container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding-top: 70px;
    position: relative;
    //background-color: #fff;
    .content-box {
        width: 100%;
        max-width: 1150px;
        margin: 0px auto;
        position: relative;
        >p {
            font-size: 18px;
            font-family: 阿里普惠体;
            margin-bottom: 8px;
        }
        .content-line-feed {
            margin-top: 40px;
        }
    }
    .content-box-phone {
        padding: 0 25px;
    }

}


/deep/ .animated {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-15px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-15px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(15px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(15px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}
</style>
