<template>
    <div class="music-box">
        <ul>
            <li :key="item.id" v-for="item in dataList">
                <div class="music-picture-box">
                    <div class="music-picture-bg">
                        <img @click="$toArticle(item)" class="music-picture diy-cursor" :src="$imagePath(item.themePictureFileId)" alt="">
                    </div>
                </div>
                <div class="music-info-box">
                    <div class="music-info-bg">
                        <div class="music-info">
                            <div class="info-title">
                                <h2 @click="$toArticle(item)" class="diy-cursor">{{item.name}}</h2>
                                <span>/</span>
                                <span>{{ item.author }}</span>
                            </div>
                            <tag-box-search :tags="item.tags"></tag-box-search>
                            <div class="info-intro">{{item.intro}}</div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import TagBoxSearch from "@/view/components/TagBoxSearch";
export default {
    name: "MusicList",
    components: {TagBoxSearch},
    props: {
        dataList: {
            type: Array,
            default() {return []}
        }
    }
}
</script>

<style scoped lang="less">
.music-box {
    width: 100%;
    >ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        >li {
            margin-bottom: 25px;
            list-style: none;
            min-height: 250px;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            .music-picture-box {
                height: 250px;
                width: 250px;
                background-color: #fff;
                .music-picture-bg {
                    height: 250px;
                    width: 250px;
                    box-shadow: rgb(0 0 0 / 5%) 2px 2px 20px;
                    transition: all .3s;
                    overflow: hidden;
                    border-radius: 4px 0 0 4px;
                    .music-picture {
                        height: 250px;
                        width: 250px;
                        user-select: none;
                        -webkit-user-drag: none;
                        transition: all .5s;
                    }
                }
            }
            .music-info-box {
                width: 300px;
                height: 250px;
                margin-right: 25px;
                .music-info-bg {
                    height: 250px;
                    width: 100%;
                    background-color: #fff;
                    box-shadow: rgb(0 0 0 / 5%) 2px 2px 20px;
                    transition: all .3s;
                    border-radius: 0 4px 4px 0;
                    .music-info {
                        box-sizing: border-box;
                        padding: 20px 25px;
                        .info-title {
                            margin-bottom: 10px;
                            font-family: 阿里普惠体;
                            >h2 {
                                display: inline-block;
                                font-weight: 500;
                                margin-bottom: 0;
                            }
                            >span {
                                margin-left: 5px;
                            }
                        }
                        .info-intro {
                            margin-top: 10px;
                            text-align: justify;
                            font-size: 15px;
                            line-height: 27px;
                        }
                    }
                }
            }


        }
        >li:hover {
            .music-picture-bg {
                box-shadow: rgb(0 0 0 / 15%) 2px 2px 30px;
                //opacity: 0.8;
                .music-picture {
                    transform: scale(1.05);
                }
            }
            .music-info-bg {
                box-shadow: rgb(0 0 0 / 10%) 2px 2px 30px;
            }
        }
    }

}
</style>
