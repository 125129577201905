<template>
    <span>
        <tag-box :tags="tags" :font-size="fontSize" @handleTag="handleTag"/>
        <search-modal ref="search_modal"/>
    </span>
</template>

<script>
import SearchModal from "@/view/components/SearchModal";
export default {
    name: "index",
    components: {SearchModal},
    props: {
        tags: {
            type: Array,
            default() {
                return []
            }
        },
        fontSize: {
            type: String,
            default() {
                return '14px'
            }
        }
    },
    methods: {
        handleTag(tag) {
            this.$nextTick(()=> {
                this.$refs['search_modal'].open(tag)
            })
        }
    }
}
</script>

<style scoped>

</style>