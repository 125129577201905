<template>
    <div class="movie-list-bg">
        <div v-if="realContent.musicId" style="width: 100%; position: relative; height: 65px; overflow: hidden; box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 30px;">
            <iframe height="82" style="width: calc(100% + 20px); border: none; position: absolute; top: -11px; left: -10px;"
                    :src="'https://music.163.com/outchain/player?type=2&amp;id=' + realContent.musicId + '&amp;auto=0&amp;height=66'"></iframe>
        </div>
        <div class="movie-list-preface">
            <div class="preface-content">{{realContent.preface}}</div>
        </div>
        <ul v-if="!phoneView" class="movie-list">
            <li class="movie-item" v-for="(item, index) in realContent.items" :key="index + item.title">
                <div class="movie-item-index">
                    <section style="box-sizing: border-box;   text-align: justify; font-size: 16px;">
                        <section style="margin: 0px 0% -20px; text-align: center; position: static; box-sizing: border-box;" class="_135editor">
                            <section style="color: rgb(136, 162, 197); font-size: 41px; padding: 0px 14px; line-height: 1; text-align: justify; box-sizing: border-box;">
                                <p style="text-align: center;  margin: 0px; padding: 0px; box-sizing: border-box;" align="center">
                                    <strong style="box-sizing: border-box; font-family: 阿里普惠体">{{getIndex(index)}}</strong>
                                </p>
                            </section>
                        </section>
                        <section style="display: flex; flex-flow: row nowrap; text-align: center; justify-content: center; position: static; box-sizing: border-box;" class="_135editor">
                            <section style="display: inline-block; vertical-align: top; width: auto; line-height: 0; flex: 0 0 0%; align-self: flex-start; height: auto; margin: 0px; border-right: 1px solid rgba(136, 162, 197, 0.13); border-top-right-radius: 0px; padding: 5px;box-sizing:border-box;">
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(255, 255, 255, 0);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                            </section>
                            <section style="display: inline-block; vertical-align: top; width: auto; line-height: 0; flex: 0 0 0%; align-self: flex-start; height: auto; margin: 0px; border-right: 1px solid rgba(136, 162, 197, 0.13); border-top-right-radius: 0px; padding: 5px;box-sizing:border-box;">
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(255, 255, 255, 0);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                            </section>
                            <section style="display: inline-block; vertical-align: top; width: auto; line-height: 0; flex: 0 0 0%; align-self: flex-start; height: auto; margin: 0px; border-right: 1px solid rgba(136, 162, 197, 0.13); border-top-right-radius: 0px; padding: 5px;box-sizing:border-box;">
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(255, 255, 255, 0);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                            </section>
                            <section style="display: inline-block; vertical-align: top; width: auto; line-height: 0; flex: 0 0 0%; align-self: flex-start; height: auto; margin: 0px; border-right: 1px solid rgba(136, 162, 197, 0.13); border-top-right-radius: 0px; padding: 5px;box-sizing:border-box;">
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(255, 255, 255, 0);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(255, 255, 255, 0);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                            </section>
                            <section style="display: inline-block; vertical-align: top; width: auto; line-height: 0; flex: 0 0 0%; align-self: flex-start; height: auto; margin: 0px; border-right: 1px solid rgba(136, 162, 197, 0.13); border-top-right-radius: 0px; padding: 5px;box-sizing:border-box;">
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(255, 255, 255, 0);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                            </section>
                            <section style="display: inline-block; vertical-align: top; width: auto; line-height: 0; flex: 0 0 0%; align-self: flex-start; height: auto; margin: 0px; border-right: 1px solid rgba(136, 162, 197, 0.13); border-top-right-radius: 0px; padding: 5px;box-sizing:border-box;">
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(255, 255, 255, 0);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                            </section>
                            <section style="display: inline-block; vertical-align: top; width: auto; line-height: 0; flex: 0 0 0%; align-self: flex-start; height: auto; margin: 0px; border-right: 1px solid rgba(136, 162, 197, 0.13); border-top-right-radius: 0px; padding: 5px;box-sizing:border-box;">
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                            </section>
                            <section style="display: inline-block; vertical-align: top; width: auto; line-height: 0; flex: 0 0 0%; align-self: flex-start; height: auto; margin: 0px; border-right: 0px solid rgba(136, 162, 197, 0.13); border-top-right-radius: 0px; padding: 5px;box-sizing:border-box;">
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(255, 255, 255, 0);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                                <section style="margin: 0px 0% 4px; position: static; box-sizing: border-box;">
                                    <section class="group-empty" style="display: inline-block; width: 9px; height: 9px; vertical-align: top; overflow: hidden; background-color: rgba(136, 162, 197, 0.13);box-sizing:border-box;">
                                        <svg viewbox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;box-sizing:border-box;" xml:space="default"></svg>
                                    </section>
                                </section>
                            </section>
                        </section>
                    </section>
                </div>
                <div class="douban-link">
                    <a :href="item.doubanUrl" target="_blank">
                        <svg t="1683300670071" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2311" width="25" height="25"><path d="M504.32 509.9008m-450.816 0a450.816 450.816 0 1 0 901.632 0 450.816 450.816 0 1 0-901.632 0Z" fill="#E3FFF3" p-id="2312"></path><path d="M268.0832 251.136h472.4736v75.6224H268.0832z" fill="#1DCE75" p-id="2313"></path><path d="M640.8704 693.0432l30.72-102.1952h38.2976V359.2704H298.8032v231.5776h35.584l30.72 102.1952H241.6128v75.6224h525.4144v-75.6224h-126.1568zM374.4256 434.8928h259.7888v80.3328H374.4256V434.8928z m44.4416 155.9552h168.1408l-35.2768 102.1952H454.144l-35.2768-102.1952z" fill="#05C46D" p-id="2314"></path><path d="M640.8704 693.0432l30.72-102.1952h38.2976V359.2704H298.8032v231.5776h35.584l30.72 102.1952H241.6128v75.6224h525.4144v-75.6224h-126.1568zM374.4256 434.8928h259.7888v80.3328H374.4256V434.8928z m44.4416 155.9552h168.1408l-35.2768 102.1952H454.144l-35.2768-102.1952z" fill="#05C46D" p-id="2315"></path><path d="M664.8832 612.9664l6.656-22.1696h9.6256c10.496-15.1552 20.0704-30.976 28.6208-47.4112V359.2704H298.8032v231.5776h35.584l30.72 102.1952H241.6128v75.6224h191.3856c92.9792-26.3168 173.7728-81.664 231.8848-155.6992z m-113.152 80.0768H454.144l-35.2768-102.1952h168.1408l-35.2768 102.1952zM374.4256 434.8928h259.7888v80.3328H374.4256V434.8928z" fill="#1DCE75" p-id="2316"></path><path d="M631.1424 251.136H268.0832v75.6224h343.2448c8.704-24.32 15.36-49.5616 19.8144-75.6224zM374.4256 515.2256V434.8928h180.5824a447.9488 447.9488 0 0 0 43.2128-75.6224H298.8032v231.5776h35.584l2.7136 9.0112a449.51552 449.51552 0 0 0 145.2032-84.6336H374.4256z" fill="#3CD38E" p-id="2317"></path><path d="M457.5744 251.136H268.0832v75.6224h131.1232c21.9648-22.9888 41.5744-48.3328 58.368-75.6224zM364.5952 359.2704H298.8032v46.3872c23.3472-13.5168 45.3632-29.0304 65.792-46.3872z" fill="#48E1AA" p-id="2318"></path></svg>
                    </a>
                </div>
                <div class="content-img-box">
                    <img :src="'https://xuchenoak.com' + item.picUrl" alt="">
                </div>
                <div class="content-intro-box">
                    <h2 :id="index + item.title">{{item.title}}</h2>
                    <div class="intro-line">
                        <span class="intro-line-name">导演：</span>
                        <span class="intro-line-value">{{item.director}}</span>
                    </div>
                    <div class="intro-line">
                        <span class="intro-line-name">主演：</span>
                        <span class="intro-line-value">{{item.protagonist}}</span>
                    </div>
                    <div class="intro-line">
                        <span class="intro-line-name">首映：</span>
                        <span class="intro-line-value">{{item.premiere}}</span>
                    </div>
                    <div class="intro-line">
                        <span class="intro-line-name">时长：</span>
                        <span class="intro-line-value">{{item.duration}}</span>
                    </div>
                    <div class="intro-line" style="margin-top: 15px">
                        <!--                        <span class="intro-line-name">推荐：</span>-->
                        <span class="intro-line-value">{{item.recommend}}</span>
                    </div>
                </div>
            </li>
        </ul>
        <ul v-else class="movie-list-phone" ref="movie_list_phone" :style="'--liMarginTop:' + liMarginTop">
            <li class="movie-item" v-for="(item, index) in realContent.items" :key="index + item.title" @click="handlePhoneItem(item)">
                <div class="content-img-box">
                    <img :src="'https://xuchenoak.com' + item.picUrl" alt="">
                    <div class="img-bottom-gradient"></div>
                </div>
                <div class="content-title-box">
                    <div class="title-index">{{getIndex(index)}}</div>
                    <h2 :id="index + item.title" v-html="item.phoneTitle"></h2>
                </div>
            </li>
        </ul>
        <a-modal v-model="phoneModelVisible" :destroyOnClose="true" :footer="null">
            <div v-if="phoneCurrentItem" class="phone-model">
                <div class="douban-link">
                    <a :href="phoneCurrentItem.doubanUrl" target="_blank">
                        <svg t="1683300670071" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2311" width="25" height="25"><path d="M504.32 509.9008m-450.816 0a450.816 450.816 0 1 0 901.632 0 450.816 450.816 0 1 0-901.632 0Z" fill="#E3FFF3" p-id="2312"></path><path d="M268.0832 251.136h472.4736v75.6224H268.0832z" fill="#1DCE75" p-id="2313"></path><path d="M640.8704 693.0432l30.72-102.1952h38.2976V359.2704H298.8032v231.5776h35.584l30.72 102.1952H241.6128v75.6224h525.4144v-75.6224h-126.1568zM374.4256 434.8928h259.7888v80.3328H374.4256V434.8928z m44.4416 155.9552h168.1408l-35.2768 102.1952H454.144l-35.2768-102.1952z" fill="#05C46D" p-id="2314"></path><path d="M640.8704 693.0432l30.72-102.1952h38.2976V359.2704H298.8032v231.5776h35.584l30.72 102.1952H241.6128v75.6224h525.4144v-75.6224h-126.1568zM374.4256 434.8928h259.7888v80.3328H374.4256V434.8928z m44.4416 155.9552h168.1408l-35.2768 102.1952H454.144l-35.2768-102.1952z" fill="#05C46D" p-id="2315"></path><path d="M664.8832 612.9664l6.656-22.1696h9.6256c10.496-15.1552 20.0704-30.976 28.6208-47.4112V359.2704H298.8032v231.5776h35.584l30.72 102.1952H241.6128v75.6224h191.3856c92.9792-26.3168 173.7728-81.664 231.8848-155.6992z m-113.152 80.0768H454.144l-35.2768-102.1952h168.1408l-35.2768 102.1952zM374.4256 434.8928h259.7888v80.3328H374.4256V434.8928z" fill="#1DCE75" p-id="2316"></path><path d="M631.1424 251.136H268.0832v75.6224h343.2448c8.704-24.32 15.36-49.5616 19.8144-75.6224zM374.4256 515.2256V434.8928h180.5824a447.9488 447.9488 0 0 0 43.2128-75.6224H298.8032v231.5776h35.584l2.7136 9.0112a449.51552 449.51552 0 0 0 145.2032-84.6336H374.4256z" fill="#3CD38E" p-id="2317"></path><path d="M457.5744 251.136H268.0832v75.6224h131.1232c21.9648-22.9888 41.5744-48.3328 58.368-75.6224zM364.5952 359.2704H298.8032v46.3872c23.3472-13.5168 45.3632-29.0304 65.792-46.3872z" fill="#48E1AA" p-id="2318"></path></svg>
                    </a>
                </div>
                <div class="content-intro-box">
                    <h2>{{phoneCurrentItem.title}}</h2>
                    <div class="intro-line">
                        <span class="intro-line-name">导演：</span>
                        <span class="intro-line-value">{{phoneCurrentItem.director}}</span>
                    </div>
                    <div class="intro-line">
                        <span class="intro-line-name">主演：</span>
                        <span class="intro-line-value">{{phoneCurrentItem.protagonist}}</span>
                    </div>
                    <div class="intro-line">
                        <span class="intro-line-name">首映：</span>
                        <span class="intro-line-value">{{phoneCurrentItem.premiere}}</span>
                    </div>
                    <div class="intro-line">
                        <span class="intro-line-name">时长：</span>
                        <span class="intro-line-value">{{phoneCurrentItem.duration}}</span>
                    </div>
                    <div class="intro-line" style="margin-top: 15px">
                        <!--                        <span class="intro-line-name">推荐：</span>-->
                        <span class="intro-line-value">{{phoneCurrentItem.recommend}}</span>
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>

export default {
    name: "index",
    props: {
        content: {
            type: String,
            default() {return ''}
        }
    },
    data() {
        return {
            realContent: '',
            phoneView: false,
            liMarginTop: '20px',
            phoneCurrentItem: null,
            phoneModelVisible: false
        }
    },
    watch: {
        '$store.state.phoneView'() {
            this.phoneView = this.$store.state.phoneView
            this.initPhoneLiMarginTop()
        }
    },
    created() {
        this.realContent = JSON.parse(this.content)
    },
    mounted() {
        this.phoneView = this.$store.state.phoneView
        this.$nextTick(() => {
            this.$emit('init', this.getCatalogList())
        })
    },
    methods: {
        initPhoneLiMarginTop() {
            this.$nextTick(()=> {
                if (this.$refs.movie_list_phone) {
                    const width = this.$refs.movie_list_phone.offsetWidth
                    this.liMarginTop = parseInt(width*0.04 + '') + 'px'
                }
            })
        },
        handlePhoneItem(item) {
            this.phoneCurrentItem = item
            this.phoneModelVisible = true
        },
        getIndex(index) {
            return index < 9 ? '0' + (index + 1) : index + 1
        },
        getCatalogList() {
            const content = this.realContent
            if (!content || !content.items || content.items.length < 1) {
                return []
            }
            let catalogs = []
            content.items.map((item, index) => {
                catalogs.push({
                    id: index + item.title,
                    name: item.title,
                    index: 2
                })
            })
            return catalogs
        }
    }

}
</script>

<style scoped lang="less">
.movie-list-bg {
    .movie-list-preface {
        background-color: rgba(136, 162, 197, 0.1);
        padding: 20px;
        margin-top: 30px;
        .preface-content {
            font-size: 16px;
            line-height: 28px;
            text-align: justify;
        }
    }
    .movie-list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        .movie-item {
            list-style: none;
            width: 100%;
            background-color: rgba(136, 162, 197, 0.1);
            display: flex;
            flex-wrap: wrap;
            margin-top: 170px;
            position: relative;
            .movie-item-index {
                position: absolute;
                right: 0;
                top: -120px;
                width: 100%;
            }
            .douban-link {
                position: absolute;
                right: 0;
                top: 0;
                height: 45px;
                width: 45px;
                padding: 10px;
                box-sizing: border-box;
            }
            .content-img-box {
                width: 210px;
                min-height: 300px;
                overflow: hidden;
                position: relative;
                >img {
                    height: 100%;
                    width: 210px;
                }
            }
            .content-intro-box {
                padding: 20px;
                width: calc(100% - 210px);
                .intro-line {
                    font-size: 16px;
                    line-height: 28px;
                    .intro-line-name {
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .movie-list-phone {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .movie-item {
            list-style: none;
            width: 48%;
            margin-top: 50px;
            position: relative;
            .content-img-box {
                width: auto;
                height: 260px;
                position: relative;
                border-radius: 2px;
                overflow: hidden;
                >img {
                    height: 100%;
                    border-radius: 2px;
                }
                .img-bottom-gradient {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 2;
                    bottom: 0;
                    background-image: linear-gradient(180deg, transparent 50%, #444 95%);
                }
            }
            .content-title-box {
                position: absolute;
                bottom: 0;
                padding: 2px 10px;
                z-index: 3;
                width: 100%;
                .title-index {
                    color: #fff;
                    font-family: 阿里普惠体;
                    font-size: 25px;
                    opacity: .5;
                }
                >h2 {
                    font-size: 14px;
                    color: #fff;
                    font-family: 阿里普惠体;
                    line-height: 18px;
                }
            }
        }
    }
}
.phone-model {
    position: relative;
    .douban-link {
        position: absolute;
        left: 0;
        top: -5px;
        height: 45px;
        width: 45px;
        //padding: 10px;
        box-sizing: border-box;
    }
    .content-intro-box {
        width: 100%;
        padding-top: 30px;
        .intro-line {
            font-size: 16px;
            line-height: 28px;
            .intro-line-name {
                font-weight: 600;
            }
        }
    }
}

</style>
