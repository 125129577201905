import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import Moment from 'moment'
import 'ant-design-vue/dist/antd.css'
import Empty from 'ant-design-vue';
import animated from 'animate.css'
import publicStyle from '@/assets/styles/public-style.css'
import router from '@/router'
import constant from '@/assets/js/constant'
import store from '@/util/store'
import '@/util/db'

// 页面盒子
import PageBox from "@/components/PageBox";
Vue.component("PageBox", PageBox)

// 加载中
import Loading from "@/components/Loading"
Vue.component("Loading", Loading)

// 标签盒子
import TagBox from "@/components/TagBox";
Vue.component("TagBox", TagBox)

// 复制组件
import CopyText from '@/components/CopyText'
Vue.component('CopyText', CopyText)

// 空盒组件
import EmptyBox from '@/components/EmptyBox'
Vue.component('EmptyBox', EmptyBox)

// 标签输入框
import TagsInput from '@/components/TagsInput'
Vue.component('TagsInput', TagsInput)

import windowUtil from "@/util/windowUtil";
Vue.prototype.$windowUtil = windowUtil

Vue.use(publicStyle)
Vue.config.productionTip = false
Vue.use(Antd)
Vue.prototype.$constant = constant
Vue.prototype.$moment = Moment
Vue.prototype.$formatDate = (date, format)=> {
  return Moment(date).format(format)
}
Vue.prototype.$imagePath = (url) => {
    return '/prod-file/' + url
}
Vue.prototype.$toArticle = router.$toArticle
Vue.prototype.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
Vue.use(animated)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
