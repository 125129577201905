<template>
    <div class="movie-box">
        <ul>
            <li :key="item.id" v-for="item in dataList">
                <div class="movie-picture-box">
                    <div class="movie-picture-bg">
                        <img @click="$toArticle(item)" class="movie-picture diy-cursor" :src="$imagePath(item.themePictureFileId)" alt="">
                    </div>
                </div>
                <div class="movie-info-box">
                    <div class="movie-info-bg">
                        <div class="movie-info">
                            <h2 @click="$toArticle(item)" class="info-title diy-cursor">{{item.name}}</h2>
                            <tag-box-search :tags="item.tags"></tag-box-search>
                            <div class="info-intro">{{item.intro}}</div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import TagBoxSearch from "@/view/components/TagBoxSearch/index.vue";

export default {
    name: "index",
    components: {TagBoxSearch},
    props: {
        dataList: {
            type: Array,
            default() {return []}
        }
    }
}
</script>

<style scoped lang="less">
.movie-box {
    width: 100%;
    >ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        >li {
            margin-bottom: 50px;
            list-style: none;
            min-height: 300px;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            .movie-picture-box {
                height: 250px;
                width: 250px;
                margin-top: 25px;
                margin-bottom:25px;
                background-color: #f5f5f5;
                border-radius: 4px 0 0 4px;
                .movie-picture-bg {
                    box-shadow: rgb(0 0 0 / 20%) 0px 5px 15px;
                    transition: all .3s;
                    height: 300px;
                    width: 200px;
                    position: absolute;
                    left: 50px;
                    top: 0;
                    z-index: 99;
                    overflow: hidden;
                    border-radius: 4px;
                    .movie-picture {
                        height: 300px;
                        width: 200px;
                        user-select: none;
                        -webkit-user-drag: none;
                        transition: all .3s;
                        border-radius: 4px;
                    }
                }
                .movie-picture-bg:hover {
                    box-shadow: rgb(0 0 0 / 15%) 0px 5px 50px;
                    opacity: 0.8;
                }
            }
            .movie-info-box {
                width: 325px;
                height: 300px;
                position: relative;
                .movie-info-bg {
                    position: absolute;
                    top: 25px;
                    height: 250px;
                    width: 100%;
                    background-color: #f5f5f5;
                    border-radius: 0 4px 4px 0;
                    .movie-info {
                        box-sizing: border-box;
                        padding: 20px 25px;
                        .info-title {
                            margin-bottom: 10px;
                            font-family: 阿里普惠体;
                        }
                        .info-intro {
                            margin-top: 10px;
                            text-align: justify;
                            font-size: 15px;
                            line-height: 27px;
                        }
                    }
                }
            }
        }
    }

}
</style>
