<template>
    <div id="app">
        <a-config-provider :locale="locale">
            <home-page/>
        </a-config-provider>
    </div>
</template>

<script>
// import HomePage from '@/components/HomePage/HomePage'
import HomePage from '@/layout/HomePage'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { mapMutations } from 'vuex'
export default {
    name: 'App',
    components: {
        HomePage
    },
    data() {
        return {
            locale: zhCN,
        };
    },
    mounted() {
        window.onresize = () => {
            this.changePhoneView(this.$windowUtil.getViewportOffset().x < 560)
        },
        window.onscroll = () => {
            this.changeBodyScroll(document.body.scrollTop || document.documentElement.scrollTop)
        }
    },
    methods: {
        ...mapMutations(['changePhoneView', 'changeBodyScroll'])
    },
}
</script>

<style>
body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #eee;
}
body::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 20px;
}
</style>
