<template>
    <page-box :title="classify.text" :line-color="classify.color"  background="#f5f5f5" :more="{enabled: false, link: true, text: '更多……'}">
        <music-list v-if="!phoneView" :data-list="dataList"/>
        <music-list-ph v-else :data-list="dataList"/>
    </page-box>
</template>

<script>
import {listPageData} from "@/api/article";
import MusicList from "./components/MusicList.vue";
import MusicListPh from "./components/MusicListPh.vue";
export default {
    name: "index",
    components: {MusicList, MusicListPh},
    data() {
        return {
            phoneView: false,
            dataList: [],
            classify: this.$constant.classifyConst['MUSIC']
        }
    },
    created() {
        this.listData()
    },
    mounted() {
        this.phoneView = this.$store.state.phoneView
    },
    watch: {
        '$store.state.phoneView'() {
            this.phoneView = this.$store.state.phoneView
        },
    },
    methods: {
        listData() {
            listPageData("MUSIC").then(res => {
                this.dataList = res.rows
            })
        }
    }
}
</script>

<style scoped lang="less">

</style>
