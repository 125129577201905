<template>
    <page-box :title="classify.text" :line-color="classify.color"  background="#f5f5f5">
        <div class="project-box">
            <ul>
                <li :key="item.id" v-for="item in dataList" class="project-list-li" :class="phoneView ? 'project-list-li-ph' : ''">
                    <img class="project-picture diy-cursor" @click="$toArticle(item)" :src="$imagePath(item.themePictureFileId)" alt="">
                    <div class="project-info">
                        <h2 class="info-title diy-cursor" @click="$toArticle(item)">{{item.name}}</h2>
                        <div class="info-intro">{{item.intro}}</div>
                        <tag-box-search :tags="item.tags"></tag-box-search>
                    </div>
                </li>
            </ul>
        </div>
    </page-box>
</template>

<script>
import {listPageData} from "@/api/article";
import TagBoxSearch from "@/view/components/TagBoxSearch";
export default {
    name: "index",
    components: {TagBoxSearch},
    data() {
        return {
            phoneView: false,
            dataList: [],
            classify: this.$constant.classifyConst['PROJECT']
        }
    },
    created() {
        this.listData()
    },
    mounted() {
        this.phoneView = this.$store.state.phoneView
    },
    watch: {
        '$store.state.phoneView'() {
            this.phoneView = this.$store.state.phoneView
        },
    },
    methods: {
        listData() {
            listPageData("PROJECT").then(res => {
                this.dataList = res.rows
            })
        }
    }
}
</script>

<style scoped lang="less">
.project-box {
    width: 100%;
    >ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        .project-list-li {
            margin-bottom: 25px;
            margin-right: 25px;
            list-style: none;
            height: 328px;
            width: 315px;
            border-radius: 4px;
            box-shadow: rgb(0 0 0 / 5%) 2px 2px 20px;
            //box-shadow: rgb(0 0 0 / 15%) 2px 2px 50px;
            background-color: #fff;
            transition: all .3s;
            .project-picture {
                height: 200px;
                width: 100%;
                border-radius: 4px 4px 0 0;
                user-select: none;
                -webkit-user-drag: none;
            }
            .project-info {
                height: 128px;
                box-sizing: border-box;
                padding: 10px 15px;
                .info-title {
                    font-weight: 500;
                    margin-bottom: 0px;
                    font-family: 阿里普惠体;
                }
                .info-intro {
                    padding: 10px 0px;
                    text-align: justify;
                    font-size: 16px;
                }
            }
        }
        .project-list-li-ph {
            width: 100%;
            margin-bottom: 45px;
            margin-right: 0;
            height: auto;
            .project-picture {
                height: auto;
            }
        }
        .project-list-li:last-child {
            margin-bottom: 0;
        }
        .project-list-li:hover {
            box-shadow: rgb(0 0 0 / 15%) 2px 2px 30px;
        }
    }

}
</style>
