<template>
    <div class="ant-input tags-input-box">
      <span class="tags-input-tags">
        <a-tag class="tags-input-tags-tag" :key="tag.value" v-for="(tag, index) in tags" :color="tag.num === 2 ? 'pink' : ''" closable @close="delTag(index)">{{tag.value}}</a-tag>
      </span>
        <a-input class="tags-input-diy-input" v-model="value" @change="change" @keyup.delete="backSpace" :placeholder="placeholder" @pressEnter="handleSearch" />
        <a-icon v-if="search" class="tags-input-diy-search" type="search" @click="handleSearch"/>
    </div>
</template>

<script>
export default {
    name: "index",
    props: {
        placeholder: {
            type: String,
            default() {
                return '';
            }
        },
        search: {
            type: Boolean,
            default() {
                return false;
            }
        },
        tagSize: {
            type: Number,
            default() {
                return 5;
            }
        }
    },
    data() {
        return {
            tags: [],
            value: ''
        }
    },
    methods: {
        init(tags, value) {
            let _tags = []
            if (tags && tags.length > 0) {
                tags.forEach(t => {
                    _tags.push({value: t,  num: 1})
                })
            }
            this.tags = _tags
            if (value) {
                this.value = value
            }
            this.tagsToSize()
        },
        setTags(tags) {
            if (tags && tags.length > 0) {
                tags.forEach(t => { this.setTag(t) })
            }
        },
        setTag(tag) {
            if (tag) {
                tag = tag.trim()
            }
            if (tag.length == 0) {
                return false
            }
            if (!this.tags) {
                this.tags = []
            }
            const ts = this.tags.filter(t => t.value == tag)
            if (!ts || ts.length == 0) {
                this.tags.push({value: tag,  num: 1})
                this.tagsNumReset()
                this.tagsToSize()
                this.handleChange()
                return true
            }
        },
        setValue(value) {
            if (value) {
                value = value.trim()
                this.value = value
            }
        },
        getTags() {
            return this.tags
        },
        getValue() {
            return this.value
        },
        tagsToSize() {
            const l = this.tags.length
            if (l > this.tagSize) {
                this.tags.splice(this.tagSize, l)
            }
        },
        handleSearch() {
            if (this.search) {
                this.$emit('search', this.getRealValue())
            }
        },
        handleChange() {
            this.$emit('change', this.getRealValue())
        },
        getRealValue() {
            let tags = []
            if (this.tags.length > 0) {
                this.tags.forEach(t => {
                    tags.push(t.value)
                })
            }
            return {tags: tags, value: this.value}
        },
        delTag(index) {
            this.tags.splice(index, 1)
            this.handleChange()
        },
        change() {
            let v = this.value
            if (v && v.endsWith(' ')) {
                v = this.value.trim()
                this.value = v
                if (v && v.length > 0) {
                    const ts = this.tags.filter(t => t.value == v)
                    if (!ts || ts.length == 0) {
                        this.tags.push({
                            value: v,
                            num: 1
                        })
                    }
                    this.value = ''
                    this.handleChange()
                }
            } else if (v) {
                this.handleChange()
            }
            this.tagsNumReset()
            this.tagsToSize()
        },
        tagsNumReset() {
            this.tags.map(t => {
                t.num = 1
            })
        },
        backSpace() {
            const v = this.value
            const l = this.tags.length
            let r = true
            if ((!v || v == '') && l > 0) {
                const num = this.tags[l - 1].num
                if (num == 2) {
                    this.tags.splice(l - 1)
                    this.handleChange()
                    r = false
                } else {
                    this.tags[l - 1].num = num + 1
                }
            }
            if ((!v || v == '') && r) {
                this.handleChange()
            }
        }
    }
}
</script>

<style scoped lang="less">
.tags-input-box {
    padding: 0;
    height: 34px;
    display: flex;
}
.tags-input-tags {
    line-height: 32px;
    .tags-input-tags-tag {
        margin: 0 0 0 10px;
    }
}
.tags-input-diy-input {
    background: transparent;
    border: 1px solid transparent;
    flex: 1;
    padding-left: 10px;
}
.tags-input-diy-input:hover {
    background: transparent;
    border: 1px solid transparent;
}
.tags-input-diy-input:focus {
    background: transparent;
    box-shadow: 0 0 transparent;
    border: 1px solid transparent;
}
.tags-input-diy-search {
    line-height: 34px;
    padding: 0 10px;
    cursor: pointer;
}
</style>