import request from "@/util/request";


/**
 * 获取来访量
 */
export function getVisitCount() {
    return request({
        url: '/config/get_visit_count',
        method: 'get'
    })
}