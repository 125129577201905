<template>
    <page-box title="关于我" line-color="#2D99AE" id="oak_as_me">
        <span style="font-size: 17px">我就是我，颜色不一样的烟火~</span>
    </page-box>
</template>

<script>
export default {
    name: "index",
    components: {},
    methods: {

    }
}
</script>

<style scoped lang="less">

</style>
