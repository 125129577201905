<template>
    <div>
        <div v-if="shouldIncludeLayout" class="container">
            <div :class="'background-color' + showIndexBg ? '' : 'background-color-o'"></div>
            <div v-if="showIndexBg" class="background-image animated zoomOut"></div>
            <div v-else class="background-image background-image-o"></div>
            <Header/>
            <div :style="showIndexBg ? '' : 'background-color: #fff'">
                <loading :loading="loading">
                    <router-view/>
                </loading>
            </div>
            <Footer/>
            <div class="back-top">
                <a-back-top :visibilityHeight="200">
                    <div class="back-top-box">
                        <a-icon type="rocket" />
                    </div>
                </a-back-top>
            </div>
        </div>
        <div v-else>
            <router-view/>
        </div>
    </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
export default {
    name: "index",
    components: { Header, Footer},
    provide() {
        return {
            setLoading: this.setLoading
        }
    },
    data() {
        return {
            loading: false,
            showIndexBg: true
        }
    },
    watch: {
        $route(to){
            this.showIndexBg = ['/', '/as'].includes(to.path)
        }
    },
    computed: {
        shouldIncludeLayout() {
            const route = this.$route;
            // 默认为true，如果设置为false则为单独页面
            return route.meta.includeLayout == undefined ? true : route.meta.includeLayout;
        }
    },
    methods: {
        setLoading(loading = false) {
            this.loading = loading
        }
    }
}
</script>

<style lang="less" scoped>
.container {
    .background-color {
        background: #333;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: -999;
    }
    .background-color-o {
        background: #fff;
    }
    .background-image {
        position: fixed;
        width: 100%;
        height: 100%;
        background-image: url("~@/assets/image/home_bg_oean.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        transition: transform 1.5s,opacity 1s;
        transform: scale(1.05);
        opacity: 0;
        z-index: -998;
    }
    .background-image-o {
        opacity: 1;
    }
}

.back-top {
    .ant-back-top {
        bottom: 50px;
        right: 10%;
        z-index: 999999;
    }
    .back-top-box {
        background-color: rgba(0,0,0,.45);
        height: 35px;
        width: 35px;
        line-height: 35px;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        transition: all 0.3s;
        box-shadow: rgb(0 0 0 / 5%) 2px 2px 20px;
        color: #fff;
    }
    .back-top-box:hover {
        background-color: rgba(0,0,0,.65);
    }
}


@-webkit-keyframes zoomOut {
    from {
        opacity: 0;
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes zoomOut {
    from {
        opacity: 0;
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.zoomOut {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut;
}
</style>
