<template>
    <div class="content-container animated fadeInDown">
        <empty-box v-if="!isShow"/>
        <div v-else class="content-box">
            <div class="article-bg">
                <ul class="article-ul">
                    <li :class="['article-li', 'animated fadeInDown','diy-cursor',item.year ? 'article-year' : 'article-title']"
                        :key="item.id"
                        v-for="(item, index) in itemList"
                        :style="{'--color': classifyConst[item.classify] && classifyConst[item.classify].color, 'animation-delay': index/10 + 's'}">
                        <div class="title-date" v-if="!item.year">{{$formatDate(item.sendTime, 'MM-DD')}}</div>
                        <div class="title-date animated fadeInLeft" v-else>{{item.year}}</div>
                        <div v-if="!item.year" class="title-value" @click="$toArticle(item)">
                            <span>{{item.name}}</span>
                            <span style="padding: 0px 10px; font-size: 10px; color: #aaa">/</span>
                            <span style="font-size: 10px; color: #aaa">{{classifyConst[item.classify].text}}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import {listData} from "@/api/article";

export default {
    name: "index",
    props: {
        classify: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    inject: ['setLoading'],
    data() {
        return {
            itemList: [],
            classifyConst: this.$constant.classifyConst
        }
    },
    mounted() {
        this.getItemList()
    },
    computed: {
        isShow() {
            return this.itemList && this.itemList.length > 0
        }
    },
    methods: {
        getItemList() {
            this.setLoading(true)
            listData().then(res => {
                const itemList = []
                if (res.code == 200) {
                    let year = null;
                    res.rows.map(item => {
                        const y = this.$formatDate(item.sendTime, 'YYYY')
                        if (year != y) {
                            year = y
                            itemList.push({
                                year: year
                            })
                        }
                        itemList.push(item)
                    })
                }
                this.itemList = itemList
            }).finally(()=> {
                this.setLoading(false)
            })

        }
    }
}
</script>

<style lang="less" scoped>
.delay6s {
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    -o-animation-delay: 1.5s;
    animation-delay: 1.5s;
}
.content-container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 40px 10px 0px 0px;
    position: relative;
    background-color: #fff;
    .content-box {
        width: 100%;
        max-width: 1150px;
        margin: 0px auto;
        position: relative;
    }
    .article-bg {
        height: auto;
        padding: 100px 0 40px 0;
        .article-ul {
            margin: 0px;
            padding: 0px 0px 0px 25px;
        }
        .article-li {
            list-style: none;
            height: 40px;
            margin-bottom: 40px;
            position: relative;
            height: auto;
            cursor: pointer;
        }
        .article-title {
            position: relative;
            border-bottom: 1px dashed #bbb;
            transition: all 0.5s;
            width: 100%;
            .title-date {
                position: absolute;
                padding: 0px 20px;
                font-size: 1em;
                font-weight: 400;
                height: 30px;
                left: 0px;
                top: 5px;
                font-family: 阿里普惠体;
            }
            .title-value {
                width: 100%;
                padding-left: 75px;
                color: #666;
                outline: none;
                font-size: 1.1em;
                line-height: 30px;
                font-weight: 400;
                margin-bottom: 10px;
                text-align: justify;
                font-family: 阿里普惠体;
            }
        }
        .article-title:hover {
            border-bottom: 1px dashed #444;
            .title-value {
                color: #555;
            }
            .title-date {
                color: var(--color);
            }
        }
        .article-title::before {
            position: absolute;
            content: '';
            background-color: var(--color);
            border: 1px solid #fff;
            width: 8px;
            height: 8px;
            padding: 0px;
            top: 12px;
            left: -2px;
            z-index: 999;
            transition: all 0.5s;
            border-radius: 50%;
        }
        .article-year {
            .title-date {
                padding: 0px 20px;
                font-size: 1.2em;
                font-weight: 500;
                font-family: 阿里普惠体;
            }
        }
        .article-year::before {
            position: absolute;
            content: '';
            background-color: #bbb;
            border: 1px solid #fff;
            width: 10px;
            height: 10px;
            padding: 0px;
            top: calc(50% - 4px);
            left: -3px;
            z-index: 999;
            transition: all 0.5s;
            border-radius: 50%;
        }
        .article-year:hover {
            .title-date {
                color: #444;
            }
        }
        .article-year:hover::before {
            background-color: #444;
        }

    }
    .article-bg::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 25px;
        width: 4px;
        height: 100%;
        background-color: #f0f2f5;
    }

}


/deep/ .animated {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-15px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-15px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(15px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(15px, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}
</style>
