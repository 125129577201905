
const classifyConst = {
    ARTICLE: {
        text: '烂笔头',
        color:'#427AF4'
    },
    PROJECT: {
        text: '小项目',
        color:'#F5A03E'
    },
    MOVIE: {
        text: '享看看',
        color:'#2D99AE'
    },
    MUSIC: {
        text: '享听听',
        color:'#54d373'
    }
}

// 文字内容类型
const articleContentType = {
    // MarkDown/富文本
    MD: 'md',
    // MOVIE_LIST
    ML: 'ml',
    // 链接跳转
    LINK: 'link'
}

export default {
    classifyConst,
    articleContentType
}
