import Vue from 'vue'
import Vuex from 'vuex'
import windowUtil from '@/util/windowUtil'

Vue.use(Vuex)
const store = new Vuex.Store({
    strict: true,
    state: {
        phoneView: windowUtil.getViewportOffset().x < 560,
        bodyScrollTop: document.body.scrollTop || document.documentElement.scrollTop,
        routerScroll: {}
    },
    mutations: {
        changePhoneView (state, val) {
            state.phoneView = val
        },
        changeBodyScroll (state, val) {
            state.bodyScrollTop = val
        }
    }
})
export default store
