<template>
    <div class="movie-box">
        <ul>
            <li :key="item.id" v-for="item in realDataList">
                <div class="movie-picture-box">
                    <div class="movie-picture-bg" :class="item.picNext ? 'movie-picture-bg-open' : ''">
                        <img class="movie-picture diy-cursor" :src="$imagePath(item.themePictureFileId)" alt="">
                        <div @click="$toArticle(item)" class="movie-picture-bottom-gradient"></div>
                        <div class="movie-picture-btn">
                            <span @click="item.picNext = !item.picNext">
                                <a-icon :style="item.picNext ? {transform: 'rotate(-90deg)'} : {transform: 'rotate(90deg)'}" type="double-right" />
                            </span>
                        </div>
                    </div>

                </div>
                <div class="movie-info-box">
                    <div class="movie-info-bg">
                        <div class="movie-info movie-info-title">
                            <h2 @click="$toArticle(item)" class="info-title diy-cursor">{{item.name}}</h2>
                            <tag-box-search :tags="item.tags"></tag-box-search>
                        </div>
                        <div class="movie-info">
                            <div class="info-intro">{{item.intro}}</div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import TagBoxSearch from "@/view/components/TagBoxSearch";
export default {
    name: "index",
    components: {TagBoxSearch},
    props: {
        dataList: {
            type: Array,
            default() {return []}
        }
    },
    data() {
      return {
          realDataList: []
      }
    },
    watch: {
        dataList: {
            immediate: true,
            handler(newVal) {
                this.realDataList = newVal.map(item => ({ ...item, picNext: false }));
            }
        }
    }
}
</script>

<style scoped lang="less">
.movie-box {
    width: 100%;
    >ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        >li {
            margin-bottom: 50px;
            list-style: none;
            min-height: 300px;
            position: relative;
            width: 100%;
            .movie-picture-box {
                position: relative;
                width: 100%;
                padding: 15px 15px 25px 15px;
                background-color: #f5f5f5;
                border-bottom: 2px dashed #fff;
                display: flex;
                flex-wrap: wrap;
                border-radius: 4px 4px 0 0;
                &:before,&:after {
                    content: '';
                    position: absolute;
                    bottom: -10px;
                    background: #fff;
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    z-index: 1;
                }
                &:before {
                    left: -10px;
                }
                &:after {
                    right: -10px;
                }
                .movie-picture-bg {
                    //box-shadow: rgb(0 0 0 / 20%) 0px 5px 15px;
                    position: relative;
                    max-height: 240px;
                    transition: max-height 1s;
                    width: 100%;
                    overflow: hidden;
                    border-radius: 4px;
                    .movie-picture {
                        width: 100%;
                        user-select: none;
                        -webkit-user-drag: none;
                        border-radius: 4px;
                    }
                    .movie-picture-bottom-gradient {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        z-index: 2;
                        bottom: 0;
                        background-image: linear-gradient(180deg, transparent 50%, #666 100%);
                    }
                    .movie-picture-btn {
                        position: absolute;
                        bottom: 10px;
                        color: #fff;
                        left: 0;
                        z-index: 3;
                        font-size: 20px;
                        width: 100%;
                        text-align: center;
                        >i {
                            transform: rotate(90deg);
                        }
                    }
                }
                .movie-picture-bg-open {
                    max-height: 700px;
                }
            }
            .movie-info-box {
                width: 100%;
                height: 100%;
                position: relative;
                .movie-info-bg {
                    width: 100%;
                    background-color: #f5f5f5;
                    border-radius: 0 0 4px 4px;
                    .movie-info {
                        box-sizing: border-box;
                        padding: 15px;
                        .info-title {
                            margin-bottom: 10px;
                            font-size: 21px;
                            font-family: 阿里普惠体;
                        }
                        .info-intro {
                            text-align: justify;
                            font-size: 15px;
                            line-height: 27px;
                        }
                    }
                    .movie-info-title {
                        padding: 20px 15px 0 15px;
                    }
                }
            }
        }
        >li:last-child {
            margin-bottom: 0;
        }
    }

}
</style>
