<template>
    <a-modal
        title="设置邀请码"
        :zIndex="99999999"
        :width="600"
        :visible="visible"
        okText="确认"
        cancelText="取消"
        @ok="handleSave"
        @cancel="handleCancel"
        :destroyOnClose="true"
    >
        <a-form :form="form">
            <a-form-item
                label="邀请码"
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                has-feedback=""
            >
                <a-input
                    class="code-input"
                    placeholder="请输入邀请码"
                    v-decorator="['code']"
                />
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>

export default {
    name: 'GetUserCode',
    data () {
        return {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            visible: false,
            form: this.$form.createForm(this),
        }
    },
    methods: {

        init() {
            const code = localStorage.getItem("talker_user_code")
            this.visible = true
            this.$nextTick(()=> {
                this.form.setFieldsValue({code: code || ''})
            })
        },

        handleSave () {
            const { form: { validateFields } } = this
            validateFields((errors, values) => {
                if (!errors) {
                    const code = values['code'] || ''
                    localStorage.setItem("talker_user_code", code)
                    this.handleCancel()
                    this.$emit('save', code)
                }
            })
        },

        handleCancel () {
            this.form.resetFields()
            this.visible = false
        },

    }
}
</script>
<style scoped lang="less">
.code-input {
    &:hover {
        border: 1px solid #d9d9d9;
    }
    &:focus {
        border: 1px solid #d9d9d9;
        box-shadow: 0 0 0 0 transparent!important;
    }
}
/deep/ .ant-btn-primary {
    background-color: #07c160;
    border-color: #07c160;
}
/deep/ .ant-btn:hover {
    color: #07c160;
    border-color: #07c160;
}
/deep/ .ant-btn-primary:hover {
    color: #fff;
}
/deep/ .ant-btn:focus {
    color: #07c160;
    border-color: #07c160;
}
/deep/ .ant-btn-primary:focus {
    color: #fff;
}
</style>
