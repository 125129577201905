<template>
    <div class="container">
        <div class="info-article-bg" v-if="pageShow">
            <a-row>
                <a-col :span="catalogShow ? 17 : 24" class="article-content-bg">
                    <article-view v-if="article.id > 0" ref="articleView" @initCatalog="initCatalog" :article="article" :original="true"></article-view>
                </a-col>
                <a-col :span="catalogShow ? 7 : 0" :class="phoneView ? 'article-catalog-box-ph' : ''">
                    <div class="article-catalog-name">大纲</div>
                    <div class="article-catalog-close" @click="catalogSwitch">
                        <a-icon type="close" />
                    </div>
                    <div class="article-catalog-bg">
                        <a-anchor class="article-catalog" :offsetTop="phoneView ? 500 : 200" @click="catalogChange">
                            <a-anchor-link :href="'#' + item.id"
                                           :key="item.id"
                                           :title="item.name"
                                           v-for="item in catalogList"
                                           :style="'padding-left:' + (item.index * 16) + 'px'"
                            />
                        </a-anchor>
                    </div>
                </a-col>
            </a-row>
            <div v-if="!catalogShow" class="catalog-button-box" @click="catalogSwitch">
                <a-icon type="bars" />
            </div>
        </div>
        <empty-box v-else/>
    </div>
</template>

<script>
// import { decode } from 'js-base64';
import ArticleView from "@/view/components/ArticleView"
import {getData} from "@/api/article";
export default {
    components: {ArticleView},
    inject: ['setLoading'],
    data() {
        return {
            phoneView: false,
            content: '',
            pageShow: true,
            article: {},
            catalogShow: true,
            catalogList: []
        }
    },
    created() {
        this.initArticle()
    },
    mounted() {
        this.phoneView = this.$store.state.phoneView
        if (this.phoneView) {
            this.catalogShow = !this.phoneView
        }
    },
    watch: {
        '$store.state.phoneView'() {
            this.phoneView = this.$store.state.phoneView
            this.catalogShow = !this.phoneView
        },
    },
    methods: {
        initArticle() {
            let id = this.$route.params['id']
            if (id) {
                this.setLoading(true)
                getData(id).then(res => {
                    if (res.code == 200) {
                        this.article = res.data
                    }
                }).finally(()=> {
                    this.setLoading(false)
                })
            } else {
                this.pageShow = false
            }
        },
        initCatalog(catalogList) {
            const titleCatalogs = [{
                id: 'super_title_' + this.article.id,
                name: this.article.name,
                tagName: 'H1',
                index: 1
            }]
            if (catalogList && catalogList.length > 0) {
                this.catalogList = [...titleCatalogs, ...catalogList]
            } else {
                this.catalogList = titleCatalogs
            }
        },
        catalogSwitch() {
            this.catalogShow = !this.catalogShow
        },
        catalogChange() {
            if (this.phoneView) {
                this.catalogSwitch()
            }
        }
    }
}
</script>

<style lang="less" scoped>

.container {
    padding-top: 80px;
    background-color: #fff;
    min-height: 100vh;

    .info-article-bg {
        max-width: 1200px;
        margin: 0 auto;
        padding: 50px 25px;
        .article-catalog-box-ph {
            position: fixed;
            background: #fff;
            height: 100%;
            width: 100%;
            z-index: 9999;
            top: 80px;
            left: 10px;
            padding-top: 30px;
        }
        .article-catalog-name {
            position: fixed;
            margin-left: 50px;
            //margin-top: 60px;
            width: 280px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            border-bottom: 1px solid #f5f5f5;
            padding-left: 4px;
            padding-bottom: 10px;
        }
        .article-catalog-close {
            position: fixed;
            margin-left: 300px;
            //margin-top: 60px;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            padding-left: 4px;
            padding-bottom: 10px;
            color: #999;
            cursor: pointer;
        }
        .article-catalog-close:hover {
            color: #666;
        }
        .article-catalog-bg {
            //background-color: #fff;
            position: fixed;
            height: calc(100vh - 250px);
            width: 280px;
            overflow-y: auto;
            margin-left: 50px;
            margin-top: 50px;
            padding-left: 5px;
            //box-shadow: rgb(0 0 0 / 5%) 2px 2px 20px;
            z-index: 1;
            .article-catalog {
                width: 100%!important;
                height: 100%!important;
                transition: all .5s;
            }
        }
        .catalog-button-box {
            background-color: rgba(0,0,0,.45);
            height: 35px;
            width: 35px;
            line-height: 35px;
            text-align: center;
            font-size: 20px;
            font-weight: 700;
            transition: all 0.3s;
            box-shadow: rgb(0 0 0 / 5%) 2px 2px 20px;
            color: #fff;
            position: fixed;
            right: calc(10% + 5px);
            bottom: 100px;
            cursor: pointer;
            z-index: 999999;
        }
        .catalog-button-box:hover {
            background-color: rgba(0,0,0,.65);
        }
    }
}

/deep/ .ant-affix {
    position: absolute !important;
    top: 0px !important;
    width: calc(100% - 5px) !important;
    height: 100% !important;

}

/deep/ .ant-anchor-wrapper {
    background-color: transparent;
    overflow: visible;
}

/deep/ .ant-anchor-link {
    line-height: 25px;
    padding-top: 2px;
    padding-bottom: 2px;
}


</style>
