<template>
    <div class="background-box">
        <div class="chat-container" :class="{'chat-container-magnify': magnify, 'chat-container-phone': phoneView && menuView, 'chat-container-phone-active': phoneView && !menuView, 'close-talker-box': !menuView}">
            <div class="talker-box">
                <div class="box-header">
                    <div class="header-title">小许同学</div>
                    <div class="header-btn">
                        <div class="btn-box-item" title="设置邀请码" @click="handleSetUserCode">
                            <a-icon class="item-icon" type="safety"/>
                        </div>
                        <div class="btn-box-item" title="新对话" @click="handleAddTalker" style="margin-left: 10px">
                            <a-icon class="item-icon" type="plus"/>
                        </div>
                    </div>
                </div>
                <div class="box-content">
                    <div class="content-item" :class="item.id === activeTalker.id ? 'content-item-active' : ''" v-for="item in talkerList" :key="item.id" @click="handleTalker(item)">
                        <div class="item-bg">
                            <div class="item-title">{{item.title}}</div>
                            <div class="item-bottom">
                                <div class="bottom-time">{{$formatDate(item.createTime, 'YYYY-MM-DD HH:mm:ss')}}</div>
                                <div class="bottom-edit">
                                    <a-icon class="edit-item" type="edit" @click.stop="handleTalkerEdit(item)"/>
                                    <a-icon class="edit-item" type="delete" @click.stop="handleTalkerDel(item)" style="padding-left: 15px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chat-box">
                <div class="box-header">
                    <div class="header-title">
                        <div v-if="phoneView" class="btn-box-item" title="返回" @click="menuView = !menuView">
                            <a-icon class="item-icon" type="arrow-left"/>
                        </div>
                        <a-icon v-else :type="menuView ? 'menu-fold' : 'menu-unfold'" @click="menuView = !menuView" style="font-size: 18px; cursor: pointer; color: #888" />
                        <div style="padding-left: 10px; font-size: 16px">{{activeTalker.title}}</div>
                    </div>
                    <div class="header-btns">
                        <div v-if="!phoneView" style="padding-left: 10px">
                            <a-icon title="关闭全屏" v-if="magnify" type="fullscreen-exit" style="font-size: 20px; cursor: pointer; color: #888" @click="magnify = !magnify" @keydown.esc="magnify = !magnify"/>
                            <a-icon title="开启全屏" v-else type="fullscreen" style="font-size: 20px; cursor: pointer; color: #888" @click="magnify = !magnify"/>
                        </div>
                    </div>
                </div>
                <div ref="box_content" class="box-content">
                    <loading :loading="listLoading">
                        <div style="padding: 20px 0; min-height: 200px">
                            <div class="content-line" v-if="!talkerDataList || talkerDataList.length == 0">快开始我们的聊天吧~</div>
                            <div v-else>
                                <div class="content-line" style="font-size: 11px; color: #bbb">—<span style="padding: 0 10px">就到这里了</span>—</div>
                                <div v-for="(item, index) in talkerDataList" :key="index + '_' + item.timestamp">
                                    <div v-if="item.role === 'time'" class="content-line">{{item.content}}</div>
                                    <div v-if="item.role === 'assistant'" class="content-msg content-left">
                                        <div class="msg-box msg-box-left">
                                            <div class="after-box"></div>
                                            <mark-down-view :content="item.content"/>
                                        </div>
                                    </div>
                                    <div v-if="item.role === 'assistant_user_code'" class="content-msg content-left">
                                        <div class="msg-box msg-box-left">
                                            <div class="after-box"></div>
                                            <div>
                                                <span>邀请码错误！请</span>
                                                <span style="color: #1890ff; padding: 0 2px; cursor: pointer" @click="handleSetUserCode">点击</span>
                                                <span>重新配置</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="item.role === 'user'" class="content-msg content-right">
                                        <div class="msg-box msg-box-right">
                                            <p :key="index + '_' + item" v-for="(item, index) in splitContent(item.content)">{{item}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="submitLoading" class="content-line">
                                <a-icon type="loading" />
                                <span style="margin-left: 10px">对方输入中...</span>
                            </div>
                        </div>
                    </loading>
                </div>
                <div class="box-footer">
                    <a-textarea class="footer-area" placeholder="输入消息" v-model="value" :autoSize="{minRows: 3, maxRows: 3}" :maxLength="4000" @keydown.enter.prevent="handleEnterKey"/>
                    <div class="footer-submit">
                        <span style="margin-right: 10px; color: #999">{{value.length}}/4000</span>
                        <span style="display: inline-block; width: 100px; text-align: right">
                            <a-button class="submit-btn" type="primary" block @click="handleSubmit" :disabled="submitLoading">发送</a-button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <get-user-code ref="get_user_code" @save="handleSaveUserCode"/>
        <edit-talker ref="edit_talker" @save="handleEditTalkerSave"/>
    </div>
</template>

<script>
import GetUserCode from "./components/GetUserCode"
import EditTalker from "./components/EditTalker"
import MarkDownView from '@/components/MarkDownView'
import {v4 as uuid} from 'uuid'
import {fetchEventSource} from '@microsoft/fetch-event-source'

const ctrl = new AbortController();

export default {
    name: "index",
    components: {
        GetUserCode,
        EditTalker,
        MarkDownView
    },
    data() {
        return {
            phoneView: false,
            magnify: false,
            menuView: true,
            listLoading: false,
            submitLoading: false,
            talkerList: [],
            activeTalker: {
                id: '',
                title: '',
                createTime: null
            },
            talkerDataList: [],
            total: 0,
            value: '',
            errorMsg: '不好意思！小许同学离线了~'
        }
    },
    created() {
        this.init()
    },
    mounted() {
        this.phoneView = this.$store.state.phoneView
    },
    watch: {
        '$store.state.phoneView'() {
            this.phoneView = this.$store.state.phoneView
        }
    },
    methods: {
        init() {
            this.talkerList = []
            this.$db.getTalker(this.$dbKey.taker).then(res => {
                if (res) {
                    this.talkerList = JSON.parse(res) || []
                }
            }).finally(()=> {
                if (this.talkerList.length == 0) {
                    this.addTalker()
                } else {
                    let talkerActive = this.talkerList[0]
                    this.$db.getTalker(this.$dbKey.takerActiveId).then(activeId => {
                        if (activeId) {
                            const activeTalkerList = this.talkerList.filter(item => item.id === activeId)
                            if (activeTalkerList && activeTalkerList.length == 1) {
                                talkerActive = activeTalkerList[0]
                            }
                        }
                    }).finally(() => {
                        this.handleTalker(talkerActive)
                    })
                }
            })
        },
        listTalkerData() {
            this.talkerDataList = []
            this.listLoading = true
            this.$db.getTalker(this.getTalkerLogKey(this.activeTalker.id)).then(res => {
                if (res) {
                    this.talkerDataList = JSON.parse(res)
                    this.scrollToBottom()
                }
            }).finally(()=> {
                this.listLoading = false
            })
        },
        handleEditTalkerSave() {
            this.saveTalkerList()
        },
        handleAddTalker() {
            this.addTalker()
        },
        addTalker(title = '') {
            if (this.talkerList.length >= 10) {
                this.$message.warn('最多只可创建10个对话')
                return
            }
            const talker = {
                id: uuid(),
                title: title || '新对话',
                createTime: new Date().getTime()
            }
            this.talkerList.push(talker)
            this.handleTalker(talker)
            this.saveTalkerList()
        },
        saveTalkerList() {
            this.$db.saveTalker(this.$dbKey.taker, JSON.stringify(this.talkerList))
        },
        handleTalker(talker) {
            this.activeTalker = talker
            this.$db.saveTalker(this.$dbKey.takerActiveId, talker.id)
            this.listTalkerData()
            if (this.phoneView) {
                this.menuView = false
            }
        },
        handleTalkerEdit(talker) {
            this.$nextTick(()=> {
                this.$refs['edit_talker'].init(talker)
            })
        },
        handleTalkerDel(talker) {
            if (this.talkerList.length == 1) {
                this.$message.warn("请至少保留一个对话")
                return
            }
            this.$confirm({
                title: '您确认删除该对话吗？',
                content: `${talker.title}`,
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk: ()=> {
                    this.talkerList = this.talkerList.filter(item => item.id !== talker.id)
                    if (talker.id === this.activeTalker.id && !this.phoneView) {
                        this.handleTalker(this.talkerList[0])
                    }
                    this.$db.saveTalker(this.$dbKey.taker, JSON.stringify(this.talkerList))
                }
            })
        },
        clearData() {
            this.total = 0
            this.talkerDataList = []
        },
        scrollToBottom() {
            this.$nextTick(()=> {
                const cel = this.$refs['box_content']
                cel.scrollTop = cel.scrollHeight
            })
        },
        handleSetUserCode() {
            this.$nextTick(()=> {
                this.$refs['get_user_code'].init()
            })
        },
        async handleSubmit() {
            if (this.submitLoading) {
                return
            }
            const userCode = localStorage.getItem("talker_user_code")
            if (!userCode || userCode.length == 0 || userCode == 'undefined') {
                this.$refs['get_user_code'].init()
                return
            }
            if (this.value.trim().length === 0) {
                this.value = ''
                return
            }
            if (this.value.length > 4000) {
                this.$message.warn("仅支持发送4000字以内的消息")
                return
            }
            this.addRightTalkerMsg()
            this.submitLoading = true
            const dataList = [{
                "role": "system",
                "content": "你是小许同学，一个基于chat-gpt的文本对话助手"
            }]

            // 如果有历史总结则加入历史总结
            const sumUpItem = await this.getSumUpItem()
            if (sumUpItem) {
                dataList.push(sumUpItem)
            }
            const talkerDataList = this.talkerDataList.filter(item => {
                return ['user', 'assistant'].includes(item.role) && (sumUpItem ? item.timestamp > sumUpItem.timestamp : true)
            })
            if (talkerDataList && talkerDataList.length > 0) {
                // 加入历史之后的聊天记录
                talkerDataList.forEach(item => {
                    dataList.push({
                        role: item.role,
                        content: item.content
                    })
                })
            }

            // 发送请求
            let resValue = ''
            let first = true
            let suc = false
            fetchEventSource('/prod-api/chat/chat_msg_stream', {
                method: 'POST',
                body: JSON.stringify({
                    userCode: userCode,
                    // model: 'gpt-3.5-turbo',
                    messages: dataList
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
                signal: ctrl.signal,
                onopen: (response) => {
                    if (response.ok) {
                        return
                    }
                    this.talkerDataList.push({
                        role: 'assistant',
                        content: this.errorMsg,
                        timestamp: new Date().getTime()
                    })
                },
                onmessage: (ev) => {
                    this.submitLoading = false
                    const dataStr = ev.data
                    if (dataStr.startsWith("[MESSAGE]:")) {
                        const msgArray = dataStr.split(":")
                        const msg = msgArray && msgArray.length == 2 ? msgArray[1] || this.errorMsg : this.errorMsg
                        if (msg === '邀请码错误') {
                            this.talkerDataList.push({
                                role: 'assistant_user_code',
                                content: msg,
                                timestamp: new Date().getTime()
                            })
                        } else {
                            this.talkerDataList.push({
                                role: 'assistant',
                                content: msg,
                                timestamp: new Date().getTime()
                            })
                        }
                        return
                    }
                    else if (dataStr != '[DONE]') {
                        suc = true
                        let v = ''
                        try {
                            const data = JSON.parse(dataStr)
                            v = data['choices'][0]['delta']['content'] || ''
                        } catch (e) {
                            console.log('解析消息失败', e)
                        }
                        resValue += v
                        if (first) {
                            first = false
                            this.talkerDataList.push({
                                role: 'assistant',
                                content: resValue,
                                timestamp: new Date().getTime()
                            })
                        } else {
                            const lastIndex = this.talkerDataList.length - 1
                            this.talkerDataList[lastIndex].content = resValue
                        }
                        this.scrollToBottom()
                    }
                },
                // onclose() {
                //     console.log('连接关闭')
                // },
                // onerror(err) {
                //     console.log('连接错误', err)
                // }
            }).then(() => {
                if (suc) {
                    this.$db.saveTalker(this.getTalkerLogKey(this.activeTalker.id), JSON.stringify(this.talkerDataList))
                    // 触发总结聊天记录
                    this.sumUpTalkerLog()
                }
            }).finally(()=> {
                this.scrollToBottom()
                this.submitLoading = false
            })
            this.value = ''
            this.scrollToBottom()
        },
        async getSumUpItem() {
            let sumUpItem = null
            const sumUpItemStr = await this.$db.getTalker(this.getTalkerLogSumUpKey(this.activeTalker.id))
            if (sumUpItemStr) {
                sumUpItem = JSON.parse(sumUpItemStr)
            }
            return sumUpItem
        },
        // 总结聊天记录
        async sumUpTalkerLog() {
            const userCode = localStorage.getItem("talker_user_code")
            if (!userCode || userCode.length == 0 || userCode == 'undefined') {
                this.$refs['get_user_code'].init()
                return
            }
            let sumUpItem = await this.getSumUpItem()
            const talkerDataList = this.talkerDataList.filter(item => {
                return ['user', 'assistant'].includes(item.role) && (sumUpItem ? item.timestamp > sumUpItem.timestamp : true)
            })
            if (talkerDataList.length > 4) {
                const lastTimestamp = talkerDataList[talkerDataList.length - 1].timestamp
                const dataList = [{
                    "role": "system",
                    "content": "你是小许同学，一个基于chat-gpt的文本对话助手"
                }]
                // 如果有历史总结则加入历史总结
                if (sumUpItem) {
                    dataList.push(sumUpItem)
                }
                // 加入历史之后的聊天记录
                talkerDataList.forEach(item => {
                    dataList.push({
                        role: item.role,
                        content: item.content
                    })
                })
                // 加入总结要求
                dataList.push({
                    role: "system",
                    content: "简要总结一下对话内容，用作后续的上下文提示 prompt，控制在 200 字以内"
                })
                let resValue = ''
                let suc = false
                fetchEventSource('/prod-api/chat/chat_msg_stream', {
                    method: 'POST',
                    body: JSON.stringify({
                        userCode: userCode,
                        // model: 'gpt-3.5-turbo',
                        messages: dataList
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    signal: ctrl.signal,
                    async onopen(response) {
                        if (response.ok) {
                            return
                        }
                    },
                    onmessage: (ev) => {
                        this.submitLoading = false
                        const dataStr = ev.data
                        if (dataStr.startsWith("[MESSAGE]:")) {
                            const msgArray = dataStr.split(":")
                            console.log('服务异常', msgArray && msgArray.length == 2 ? msgArray[1] : this.errorMsg)
                            return
                        }
                        else if (dataStr != '[DONE]') {
                            suc = true
                            let v = ''
                            try {
                                const data = JSON.parse(dataStr)
                                v = data['choices'][0]['delta']['content'] || ''
                            } catch (e) {
                                console.log('解析消息失败', e)
                            }
                            resValue += v
                        }
                    },
                    // onclose() {
                    //     console.log('连接关闭')
                    // },
                    // onerror(err) {
                    //     console.log('连接错误', err)
                    // }
                }).finally(()=> {
                    if (suc && resValue) {
                        this.$db.saveTalker(this.getTalkerLogSumUpKey(this.activeTalker.id), JSON.stringify({
                            role: "system",
                            content: "这是历史聊天总结作为前情提要：" + resValue,
                            timestamp: lastTimestamp
                        }))
                    }
                })
            }
        },
        getTalkerLogKey(id) {
            return this.$dbKey.takerLog + ':' + id
        },
        getTalkerLogSumUpKey(id) {
            return this.$dbKey.takerLogSumUp + ':' + id
        },
        addRightTalkerMsg() {
            const thisTime = new Date()
            const len = this.talkerDataList.length
            if (len > 0) {
                const lastTimestamp = this.talkerDataList[len - 1]['timestamp']
                if (thisTime.getTime() - lastTimestamp > 300000) {
                    this.talkerDataList.push({
                        role: 'time',
                        content: this.$formatDate(thisTime, 'YYYY-MM-DD HH:mm'),
                        timestamp: thisTime.getTime()
                    })
                }
            }
            this.talkerDataList.push({
                role: 'user',
                content: this.value,
                timestamp: thisTime.getTime()
            })
            // 将第一次发送的聊天信息作为设置为默认标题
            const userTalkerDataList = this.talkerDataList.filter(item => item.role === 'user')
            if (userTalkerDataList && userTalkerDataList.length == 1) {
                let title = userTalkerDataList[0].content
                if (title) {
                    if (title.length > 20) {
                        title = title.substring(0, 20) + '...'
                    }
                    this.activeTalker.title = title
                    this.talkerList.forEach(item => {
                        if (item.id === this.activeTalker.id) {
                            item.title = title
                        }
                    })
                    this.saveTalkerList()
                }
            }
        },
        handleEnterKey(event) {
            if (event.shiftKey || this.phoneView) {
                this.value += '\n'
            } else {
                this.handleSubmit()
            }
        },
        handleSaveUserCode() {
            // this.listTalkerData()
        },
        splitContent(content) {
            return content.split('\n')
        }
    }
}
</script>

<style scoped lang="less">
.background-box {
    height: 100vh;
    width: 100%;
    background: #e9e9e9;
    .btn-box-item {
        height: 30px;
        width: 30px;
        border: 1px solid #eee;
        border-radius: 5px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        //transition: all .3s;
        .item-icon {
            font-size: 16px;
            color: #aaa;
            //transition: all .3s;
        }
        &:hover {
            border: 1px solid #888;
            .add-talker-icon {
                color: #888;
            }
        }
    }
}
.chat-container {
    position: relative;
    height: calc(100vh - 50px);
    max-width: 1200px;
    padding-top: 50px;
    margin: 0 auto;
    background: transparent;
    display: flex;
    flex-direction: row;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.02);
    .talker-box {
        height: 100%;
        width: 300px;
        background: #fff;
        border-radius: 5px 0 0 5px;
        border-right: 1px solid #e3e4e5;
        position: relative;
        z-index: 1;
        .box-header {
            box-sizing: border-box;
            height: 60px;
            line-height: 60px;
            border-bottom: 1px solid #e3e4e5;
            padding: 0 24px;
            border-radius: 5px 0 0 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .header-title {
                padding-left: 40px;
                position: relative;
                font-size: 16px;
            }
            .header-title:before {
                content: '';
                position: absolute;
                top: 15px;
                left: 0;
                height: 30px;
                width: 30px;
                background-image: url("~@/assets/image/talker_left.jpg");
                background-repeat: no-repeat;
                background-size: 30px;
                border-radius: 5px;
            }
            .header-btn {
                display: flex;
                align-items: center;
            }
        }
        .box-content {
            height: calc(100% - 60px);
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                width: 0;
                height: 0;
                background-color: transparent;
            }
            .content-item {
                height: 70px;
                padding: 0 20px;
                cursor: pointer;
                margin: 1px 0;
                position: relative;
                transition: all .1s;
                user-select: none;
                &:nth-child(1) {
                    margin-top: 0;
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 0;
                    width: 2px;
                    background: transparent;
                    transition: all .1s;
                }
                .item-bg {
                    height: 70px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    position: relative;
                    &::before {
                        content: '';
                        height: 1px;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        bottom: -1px;
                        background: #eee;
                    }
                    .item-title {
                        height: 30px;
                        width: 260px;
                        line-height: 30px;
                        font-size: 16px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .item-bottom {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        .bottom-time {
                            font-size: 12px;
                            color: #999;
                        }
                        .bottom-edit {
                            padding-right: 10px;
                            opacity: 0;
                            .edit-item {
                                font-size: 16px;
                                cursor: pointer;
                                color: #999;
                                transition: all .3s;
                                &:hover {
                                    color: #666;
                                }
                            }
                        }
                    }
                }
            }
            .content-item:hover,.content-item-active {
                background: #f5f5f5;
                .item-bg .item-bottom .bottom-edit {
                    opacity: 1;
                }
                &::before {
                    height: 100%;
                    background: #07c160;
                }
            }
        }
    }
    .chat-box {
        position: relative;
        z-index: 2;
        width: calc(100% - 300px);
        height: 100%;
        background: #fff;
        border-radius: 0 5px 5px 0;
        >div {
            width: 100%;
            background: #fff;
        }
        .box-header {
            box-sizing: border-box;
            height: 60px;
            line-height: 60px;
            border-bottom: 1px solid #e3e4e5;
            padding: 0 24px;
            border-radius: 0 5px 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .header-title {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .header-btns {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
        .box-content {
            height: calc(100% - 220px);
            padding: 0 24px;
            box-sizing: border-box;
            overflow-y: auto;
            position: relative;
            .content-line {
                line-height: 30px;
                text-align: center;
                color: #999;
                padding-bottom: 15px;
            }
            .content-msg {
                padding-bottom: 15px;
                width: 100%;
                overflow: hidden;
                .msg-box {
                    border: 1px solid #eee;
                    border-radius: 5px;
                    max-width: calc(90% - 50px);
                    padding: 10px 15px;
                }
                .msg-box-left {
                    float: left;
                    margin-left: 50px;
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0px;
                        left: -50px;
                        height: 40px;
                        width: 40px;
                        background-image: url("~@/assets/image/talker_left.jpg");
                        background-repeat: no-repeat;
                        background-size: 40px;
                        border-radius: 5px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 10px;
                        left: -16px;
                        border-width: 10px;
                        border-style: solid;
                        border-color: transparent #eee transparent transparent;
                        z-index: 100;
                    }
                    .after-box {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 10px;
                        height: 100%;
                        background-color: #2196F3;
                        z-index: 101;
                        border-radius: 5px;
                        background-color: #fff;
                        &:after {
                            content: '';
                            position: absolute;
                            top: 10px;
                            left: -14px;
                            border-width: 10px;
                            border-style: solid;
                            border-color: transparent #fff transparent transparent;
                        }
                    }
                }
                .msg-box-right {
                    float: right;
                    margin-right: 50px;
                    background-color: #95ec69;
                    line-height: 20px;
                    font-size: 14px;
                    word-wrap:break-word;
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0px;
                        right: -50px;
                        height: 40px;
                        width: 40px;
                        background-image: url("~@/assets/image/talker_right.jpg");
                        background-repeat: no-repeat;
                        background-size: 40px;
                        border-radius: 5px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 10px;
                        right: -16px;
                        border-width: 10px;
                        border-style: solid;
                        border-color: transparent transparent transparent #95ec69;
                    }
                    /deep/ * {
                        background: transparent!important;
                    }
                    p {
                        margin-bottom: 5px;
                    }
                    p:last-child {
                        margin: 0;
                    }
                }
            }
        }
        .box-footer {
            box-sizing: border-box;
            height: 160px;
            padding: 24px;
            border-top: 1px solid #e3e4e5;
            border-radius: 0 0 5px 5px;
            position: relative;
            .footer-area {
                padding: 0;
                border: 0;
                resize:none;
                border-radius: 0;
                &:hover {
                    border: 0;
                }
                &:focus {
                    border: 0!important;
                    box-shadow: 0 0 0 0 transparent!important;
                }
            }
            .footer-submit {
                position: absolute;
                bottom: 24px;
                right: 24px;
                .submit-btn {
                    background-color: #07c160;
                    border-color: #07c160;
                }
            }
        }
    }
}
.chat-container-magnify {
    height: 100vh;
    padding: 0;
    max-width: 100%;
}
.chat-container-phone {
    padding: 0;
    height: 100vh;
    .talker-box {
        width: 100%;
        .content-item .item-bg .item-bottom .bottom-edit {
             opacity: 1!important;
        }
    }
    .chat-box {
        position: fixed;
        width: 100%;
        opacity: 0;
        z-index: -1;
    }
}
.chat-container-phone-active {
    padding: 0;
    height: 100vh;
    .talker-box {
        //position: fixed;
        //z-index: 999999;
        //width: 100%;
    }
    .chat-box {
        //position: fixed;
        //width: 100%;
        //opacity: 0;
    }
}
.close-talker-box {
    .talker-box {
        position: fixed;
        z-index: -1;
        opacity: 0;
    }
    .chat-box {
        width: 100%;
        border-radius: 5px;
        .box-header {
            border-radius: 5px 5px 0 0;
        }
    }
}
/deep/ .v-note-wrapper {
    min-height: auto;
    min-width: auto;
    p {
        margin-top: 0;
        margin-bottom: 5px;
        line-height: 20px;
        font-size: 14px;
    }
    p:last-child {
        margin-bottom: 0
    }
    * {
        line-height: 25px;
        font-size: 14px;
        text-align: justify;
    }
}

@media screen and (min-width: 500px) {
    div::-webkit-scrollbar {
        width: 0.5em;
        height: 0.5em;
        background: transparent;
    }

    div::-webkit-scrollbar-thumb {
        background-color: #eee;
        border-radius: 20px;
    }
}
</style>
