<template>
    <div class="container" :class="headerPosition">
        <div class="header-container">
            <a-row type="flex" :gutter="10" class="header-row">
                <a-col flex="100px" class="title animated fadeIn">
                    <span class="diy-cursor title-text" @click="doRouter('/')">xuchenoak</span>
                </a-col>
                <a-col flex="auto" class="menu animated fadeIn">
                    <ul class="menu-parent" :class="phoneView ? 'menu-parent-phone' : ''">
                        <li :key="item.title" class="menu-item diy-cursor" :class="phoneView && item.checked ? 'menu-item-checked' : ''" v-for="item in menuList" @click="doRouter(item.router)">
                            <a-icon :type="item.iconType" :style="item.iconColor && [{color: item.iconColor}]"/>
                            <span v-if="!phoneView || item.checked" class="menu-title alipht-regular">{{item.title}}</span>
                        </li>
                        <li key="搜索" class="menu-item diy-cursor" :class="phoneView ? 'menu-item-pone' : ''" @click="searchSwitch">
                            <a-icon type="search"/>
                            <span v-if="!phoneView" class="menu-title alipht-regular">搜索</span>
                        </li>
                    </ul>
                </a-col>
            </a-row>
        </div>
        <search-modal ref="search_open"></search-modal>
    </div>
</template>

<script>

import SearchModal from "@/view/components/SearchModal";

export default {
    name: "Header",
    components: {SearchModal},
    data() {
        return {
            menuList: [
                {
                    iconType: 'home',
                    title: '首页',
                    router: '/',
                    checked: true,
                },
                {
                    iconType: 'code',
                    iconColor: '#427AF4',
                    title: '归档',
                    router: '/timeline',
                    checked: false,
                },
                {
                    iconType: 'coffee',
                    iconColor: '#2D99AE',
                    title: '关于',
                    router: '/as',
                    checked: false,
                }
            ],
            phoneView: false,
            viewPortX: 0,
            headerPosition: 'header-absolute'
        }
    },
    created() {
        this.handleHeaderPosition()
    },
    mounted() {
        this.phoneView = this.$store.state.phoneView
    },
    watch: {
        '$store.state.phoneView'() {
            this.phoneView = this.$store.state.phoneView
        },
        '$store.state.bodyScrollTop'() {
            this.handleHeaderPosition()
            this.cachePageScroll()
        },
        '$route.path'() {
            this.handleHeaderPosition()
            this.$nextTick(()=> {
                this.initPageScroll()
            })
        }
    },
    methods: {
        handleHeaderPosition() {
            this.menuList.map(item => {
                item.checked = item.router === this.$route.path
            })
            if (['/', '/as'].includes(this.$route.path)) {
                const bodyScrollTop = this.$store.state.bodyScrollTop
                this.headerPosition = bodyScrollTop > this.$windowUtil.getViewportOffset().y - 100 ? 'header-fixed' : 'header-absolute'
            } else {
                this.headerPosition = 'header-fixed'
            }
        },
        cachePageScroll() {
            if (['/', '/article', '/timeline'].includes(this.$route.path)) {
                const y = this.$store.state.bodyScrollTop
                // console.log(this.$route.path + " set " + y)
                this.$store.state.routerScroll["page_scroll_" + this.$route.path] =  parseInt(y) || 0
            }
        },
        initPageScroll() {
            const y = this.$store.state.routerScroll["page_scroll_" + this.$route.path] || 0
            // console.log(this.$route.path + " get " + y)
            let timer;
            if (timer) {
                clearInterval(timer)
            }
            timer = setTimeout(()=> {
                // behavior有过渡动画
                // window.scrollTo({ top: parseInt(y) || 0, behavior: 'smooth' })
                window.scrollTo({ top: parseInt(y) || 0})
            }, 200)
        },
        doRouter(router) {
            if (this.$route.path == router){
                return
            }
            this.$router.push({
                path: router,
            })
        },
        searchSwitch() {
            this.$nextTick(()=> {
                this.$refs['search_open'].open()
            })
        }
    }
}
</script>

<style lang="less" scoped>
.container {
    width: 100%;
    z-index: 999999;
    transition: all .1s;
}
.header-absolute {
    position: absolute;
}
.header-fixed {
    position: fixed;
    background-color: rgba(255,255,255, 0.99);
    box-shadow: rgba(0,0,0,.1) 10px 5px 20px;
    transition: all .2s;
}
.header-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 80px;
    user-select: none;
    background: transparent;
    .header-row {
        margin: 0px;
        .title-text {
            font-family: CaviarDreams;
            font-weight: 600;
        }
    }
    .title {
        position: relative;
        font-size: 25px;
        height: 80px;
        line-height: 80px;
        margin-left: 25px;
        span {
            position: relative;
        }
    }
    .menu {
        height: 80px;
        position: relative;
        .menu-parent {
            position: absolute;
            right: 25px;
            top: 0px;
            height: 80px;
            margin: 0px;
            padding: 25px 0px;
        }
        .menu-item {
            list-style: none;
            width: auto;
            line-height: 30px;
            float: left;
            padding: 0px 10px;
            -webkit-transition: all .2s;
            -moz-transition: all .2s;
            -ms-transition: all .2s;
            -o-transition: all .2s;
            transition: all .2s;
            margin-left: 10px;
            border-radius: 2px;
            font-size: 14px;
            .menu-title {
                font-family: 阿里普惠体;
                padding-left: 7px;
                font-size: 14px;
            }
        }
        .menu-item-checked {
            background-color: rgba(240,242,245,0.7);
        }
        .menu-item-select {
            background-color: #f0f2f5;
        }
        .menu-item:hover {
            background-color: rgba(240,242,245,0.4);
        }
        .menu-item-phone:hover {
            background-color: rgba(240,242,245,0.7);
        }
    }
    .row-search {
        height: 50px;
        background-color: rgba(0,0,0,0.1);
        margin: 5px 15px!important;
        border-radius: 5px;
        .search-bg {
            width: 100%;
            height: 50px;
            line-height: 50px;
            margin: 0px 10px;
        }
    }
}

.title-text::after {
    opacity: 1;
    transform: translate3d(10px,5px,0);
}

.title-text::before {
    opacity: 1;
    transform: translate3d(-10px,-5px,0);
}


</style>
