<template>
    <div class="container">
        <div class="home-box-container">
            <div class="home-box-bg">
                <div class="home-box">
                    <h1 class="syst-light animated fadeInUp">Hi！欢迎访问我的小破站</h1>
                    <h2 class="syst-light animated fadeInUp">人生海海，欣喜相逢</h2>
                    <h2 v-if="visitCount > 0" class="syst-light animated zoomInRight" style="margin-top: 60px">
                        <span style="font-family: CaviarDreams; font-size: 25px">@</span>
                        第「<span style="padding: 0 6px">{{visitCount}}</span>」位访问的你
                    </h2>
                </div>
            </div>
            <div class="home-next diy-cursor" @click="handleNext()"></div>
        </div>
        <page-article></page-article>
        <page-project></page-project>
        <page-movie></page-movie>
        <page-music></page-music>
        <page-me></page-me>
    </div>
</template>

<script>
import { getVisitCount } from '@/api/config';
import PageArticle from "@/view/page/PageArticle";
import PageProject from "@/view/page/PageProject";
import PageMovie from "@/view/page/PageMovie";
import PageMusic from "@/view/page/PageMusic";
import PageMe from "@/view/page/PageMe";
    export default {
        name: "Content",
        components: {PageArticle, PageProject, PageMovie, PageMusic, PageMe},
        data() {
            return {
                visitCount: 0
            }
        },
        inject: ['setLoading'],
        mounted() {
            this.getVisitCount()
            setTimeout(() => {
            }, 1000)
        },
        methods: {
            handleNext() {
                window.scrollTo({ top: this.$windowUtil.getViewportOffset().y - 60, behavior: 'smooth' })
            },
            getVisitCount() {
                getVisitCount().then(res => {
                    if (res.data && res.data > 0) {
                        this.visitCount = res.data
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .container {
        width: 100%;
        height: 100%;
        position: relative;
        .home-box-container {
            background: transparent;
            height: 100vh;
            position: relative;
            .home-box-bg {
                position: absolute;
                top: 200px;
                width: 100%;
                z-index: 999;
                text-align: left;
                .home-box {
                    width: 100%;
                    max-width: 1200px;
                    margin: 0 auto;
                    padding: 0 25px;
                    >h1,h2 {
                        font-weight: 300;
                    }
                }
            }
            .home-next {
                position: absolute;
                bottom: 50px;
                left: 50%;
                height: 20px;
                width: 20px;
                border-radius: 2px;
                border-right: 3px solid #ccc;
                border-bottom: 3px solid #ccc;
                transform: rotate(45deg) translateX(-50%);
                transition: all .5s;
            }
            .home-next:hover {
                border-right: 3px solid #fff;
                border-bottom: 3px solid #fff;
            }
        }
    }

    @-webkit-keyframes fadeInDown {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }

    @keyframes fadeInDown {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }

    .fadeInDown {
        -webkit-animation-name: fadeInDown;
        animation-name: fadeInDown;
    }

    @-webkit-keyframes fadeInLeft {
        from {
            opacity: 0;
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-15px, 0, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }

    @keyframes fadeInLeft {
        from {
            opacity: 0;
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-15px, 0, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }
    .fadeInLeft {
        -webkit-animation-name: fadeInLeft;
        animation-name: fadeInLeft;
    }
    @-webkit-keyframes fadeInRight {
        from {
            opacity: 0;
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(15px, 0, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }

    @keyframes fadeInRight {
        from {
            opacity: 0;
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(15px, 0, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }
    .fadeInRight {
        -webkit-animation-name: fadeInRight;
        animation-name: fadeInRight;
    }
</style>
