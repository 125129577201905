<template>
    <page-box :title="classify.text" :line-color="classify.color" :more="{enabled: dataList.length === 4, link: true, text: '更多……'}" @handleMore="handleMore">
        <Article :key="article.id" :article="article" v-for="article in dataList"></Article>
    </page-box>
</template>

<script>

import Article from "@/view/components/ArticleView"
import {listPageData} from "@/api/article";
export default {
    name: "index",
    components: {Article},
    data() {
        return {
            dataList: [],
            classify: this.$constant.classifyConst['ARTICLE']
        }
    },
    created() {
        this.listData()
    },
    methods: {
        listData() {
            listPageData("ARTICLE").then(res => {
                this.dataList = res.rows
            })
        },
        handleMore() {
            if (this.$route.path == '/article'){
                return
            }
            this.$router.push({
                path: '/article'
            })
        }
    }
}
</script>

<style scoped lang="less">

</style>
