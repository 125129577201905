<template>
    <div class="container">
        <page-box v-if="dataList && dataList.length > 0" :title="classify.text" :line-color="classify.color" >
            <Article :key="article.id" :article="article" v-for="article in dataList"></Article>
            <div style="text-align: right">
                <a-pagination v-if="total > queryParams.pageSize" size="small" v-model="queryParams.pageNum" :total="total" :pageSize="queryParams.pageSize" @change="changePage"/>
            </div>
        </page-box>
        <empty-box v-else/>
    </div>
</template>

<script>

import Article from "@/view/components/ArticleView"
import {listDataByClassify} from "@/api/article";
export default {
    name: "index",
    components: {Article},
    inject: ['setLoading'],
    data() {
        return {
            dataList: [],
            classify: this.$constant.classifyConst['ARTICLE'],
            total: 0,
            queryParams: {
                classify: "ARTICLE",
                pageNum: 1,
                pageSize: 10
            }
        }
    },
    created() {
        this.listData()
    },
    methods: {
        listData() {
            this.setLoading(true)
            listDataByClassify(this.queryParams).then(res => {
                let dataList = []
                if (res.code == 200) {
                    dataList = res.rows
                    this.total = res.total
                }
                this.dataList = dataList
            }).finally(()=> {
                this.setLoading(false)
            })
        },
        changePage() {
            this.listData()
        }
    }
}
</script>

<style scoped lang="less">
    .container {
        min-height: 100vh;
        background-color: #fff;
        padding-top: 80px;
    }
</style>
