<template>
    <div v-if="article.id" class="article-box">
        <h1 :id="'super_title_' + article.id" class="article-title">{{article.name}}</h1>
        <div class="article-info">
            <span class="info-time">{{$formatDate(article.sendTime, 'YYYY/MM/DD')}}</span>
            <span style="margin-right: 7px">「<span style="padding: 0 2px">{{article.visitCount}}</span>」</span>
            <tag-box-search :tags="article.tags"></tag-box-search>
        </div>
        <div v-if="original" class="article-content" :class="article.contentType && article.contentType === 'md' ? 'article-content-md' : ''">
            <component ref="articleComponent" @init="componentInit" :content="article.content" v-if="article.contentType && viewComponents[article.contentType]" :is="viewComponents[article.contentType]"/>
        </div>
        <div v-else>
            <div class="article-intro">{{article.intro}}</div>
            <div class="article-original">
                <a-button type="link" class="original-title diy-cursor" @click="handleOriginal">查看原文</a-button>
            </div>
        </div>
    </div>
</template>

<script>
import MarkDownView from "@/components/MarkDownView"
import MovieListView from "@/view/components/MovieListView"
import TagBoxSearch from "@/view/components/TagBoxSearch"
export default {
    name: "index",
    components: {TagBoxSearch},
    props: {
        original: {
            type: Boolean,
            default() {
                return false
            }
        },
        article: {
            type: Object,
            default() {
                return {
                    id: null,
                    name: '',
                    sendTime: null,
                    tags: [],
                    intro: '',
                    content: '',
                    contentType: ''
                }
            }
        }
    },
    data() {
        return {
            viewComponents: {
                'md': MarkDownView,
                'ml': MovieListView
            }
        }
    },
    methods: {
        handleOriginal() {
            this.$router.push({
                path: '/article/' + this.article.id
            })
        },
        componentInit(val) {
            this.$emit('initCatalog', val)
        }
    }
}
</script>

<style scoped lang="less">
.article-box {
    width: 100%;
    .article-title {
        text-align: justify;
        color: #444;
    }
    .article-info {
        line-height: 25px;
        .info-time {
            margin-right: 10px;
            font-size: 15px;
            color: #777;
            font-weight: 500;
            font-family: 阿里普惠体;
        }
    }
    .article-intro {
        margin-top: 30px;
        line-height: 30px;
        font-size: 18px;
        text-align: justify;
    }
    .article-content {
        margin-top: 30px;
    }
    .article-original {
        margin-top: 30px;
        padding-bottom: 60px;
        margin-bottom: 60px;
        border-bottom: 1px dashed #bbb;
        .original-title {
            font-size: 18px;
            font-weight: 100;
            padding: 0;
        }
    }
}
/deep/ .article-content-md {
    p,li {
        line-height: 2em!important;
        text-align: justify;
    }
}
</style>
