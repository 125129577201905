<template>
    <a-modal v-model="searchOpen" title="搜一搜" :destroyOnClose="true" :footer="null" :width="900" :zIndex="9999999">
        <div class="search-box-input">
            <tags-input ref="tags_input" @change="handleSearch" placeholder="请输入搜索关键字，空格可提取合集"></tags-input>
        </div>
        <loading :loading="loading">
            <div class="search-box-list">
                <div v-if="dataList && dataList.length > 0" class="list-data">
                    <div :key="article.id" v-for="article in dataList" class="article-box">
                        <h1 :id="'super_title_' + article.id" class="article-title diy-cursor" @click="$toArticle(article, true)">
                            <span v-html="article.name"></span>
                            <span style="padding: 0px 10px; font-size: 10px; color: #aaa">/</span>
                            <span style="font-size: 10px; color: #aaa">{{classifyConst[article.classify].text}}</span>
                        </h1>
                        <div class="article-info">
                            <span class="info-time">{{$formatDate(article.sendTime, 'YYYY/MM/DD')}}</span>
                            <span style="margin-right: 7px">「<span style="padding: 0 2px">{{article.visitCount}}</span>」</span>
                            <tag-box :tags="article.tags" :select-tags="selectTags" @handleTag="handleTag"></tag-box>
                        </div>
                        <div class="article-intro" v-html="article.intro"></div>
                    </div>
                    <div style="text-align: right">
                        <a-pagination size="small" v-if="total > queryParams.pageSize" v-model="queryParams.pageNum" :total="total" :pageSize="queryParams.pageSize" @change="changePage"/>
                    </div>
                </div>
                <div v-else class="list-empty">
                    <empty-box text="没有搜索到数据哦"></empty-box>
                </div>
            </div>
        </loading>
    </a-modal>
</template>

<script>
import {listSearch} from "@/api/article";

export default {
    name: "index",
    data() {
        return {
            classifyConst: this.$constant.classifyConst,
            loading: false,
            searchOpen: false,
            selectTags: [],
            dataList: [],
            total: 0,
            queryParams: {
                pageNum: 1,
                pageSize: 5
            }
        }
    },
    methods: {
        open(tag) {
            this.searchOpen = true
            if (tag && tag.length > 0) {
                this.handleTag(tag)
            } else {
                this.dataList = []
            }
        },
        injectQueryParams(_tags, _keyword) {
            let tags = ''
            if (_tags && _tags.length > 0) {
                _tags.forEach(tag => {
                    tags += tag + ','
                })
                tags = tags.substring(0, tags.length - 1)
            }
            if (tags && tags.length > 0) {
                this.queryParams['tags'] = tags
            } else {
                delete this.queryParams['tags']
            }
            if (_keyword && _keyword.length > 0) {
                this.queryParams['keyword'] = _keyword
            } else {
                delete this.queryParams['keyword']
            }
            this.queryParams.pageNum = 1
            this.listData()
        },
        listData() {
            this.loading = true
            listSearch(this.queryParams).then(res => {
                if (res.code === 200) {
                    this.dataList = res.rows
                    this.total = res.total
                }
            }).finally(()=> {
                this.loading = false
            })
        },
        handleSearch(v) {
            this.selectTags = v.tags
            this.injectQueryParams(v.tags, v.value)
        },
        handleTag(tag) {
            this.$nextTick(()=> {
                const status = this.$refs['tags_input'].setTag(tag)
                if (!this.selectTags.includes(tag) && status) {
                    this.selectTags.push(tag)
                }
            })
        },
        changePage() {
            this.listData()
        }
    }
}
</script>

<style scoped lang="less">
.search-box-input {
    margin-bottom: 15px;
}
.search-box-list {
    position: relative;
    .list-empty {
        position: relative;
        min-height: 300px;
    }
    .article-box {
        width: 100%;
        padding: 10px 3px 15px 3px;
        border-top: 1px dashed #bbb;
        .article-title {
            text-align: justify;
            color: #444;
            font-size: 20px;
            margin-bottom: 10px;
            cursor: pointer;
        }
        .article-info {
            line-height: 15px;
            .info-time {
                margin-right: 10px;
                font-size: 15px;
                color: #777;
                font-weight: 500;
                font-family: 阿里普惠体;
            }
        }
        .article-intro {
            margin-top: 10px;
            line-height: 30px;
            font-size: 15px;
            text-align: justify;
        }
    }
}
</style>