<template>
    <div class="bg">
        <mavon-editor ref="mavonEditorId"
                      previewBackground="#fff"
                      codeStyle="github"
                      @imgAdd="uploadImage" @imgDel="delImage"
                      v-model="content"/>
    </div>
</template>

<script>

// 组件地址 https://github.com/hinesboy/mavonEditor
import 'mavon-editor/dist/css/index.css';
import { mavonEditor } from 'mavon-editor'
import { upload } from '@/api/file'

export default {
    name: "index",
    components: {mavonEditor},
    data() {
        return {
            content: ''
        }
    },
    created() {
        this.getArticle()
    },
    methods: {
        init(content) {
            this.content = content
        },
        async getArticle() {
            // await import('@/assets/resource/article/md/MySQL.md').then((res)=> {
            // await import('@/assets/resource/article/md/记mybatis-plus的自动填充功能.md').then((res)=> {
            //     this.content = res.default
            //
            // })
        },
        async uploadImage(pos, $file){
            const res = await upload($file);
            console.log("pos", pos)
            console.log("res", res)
            console.log("mavonEditor", mavonEditor)
            console.log("this.$refs['mavonEditorId']", this.$refs['mavonEditorId'])
            this.$refs['mavonEditorId'].$img2Url(pos, '/prod-file/' + res.data.fileUrl)
        },
        delImage() {

        }
    }

}
</script>

<style scoped class="less">
.bg {
    height: auto;
    background-color: #eee;
    padding: 100px 20px 20px 20px;
    /*max-width: 1200px;*/
    margin: 0 auto;
}
/deep/ .hljs {
    background: transparent;
}
/deep/ .v-note-wrapper {
    border: none;
}
/*/deep/ .v-note-wrapper .v-note-panel .v-note-show .v-show-content, .v-note-wrapper .v-note-panel .v-note-show .v-show-content-html {*/
/*  padding: 0;*/
/*}*/
</style>
